import styled from "styled-components";
import { colors, mediaMax, mediaMin, zIndex } from "utils/theme";

export const SidebarWrapper = styled.div`
  height: 100%;

  ${mediaMax("lg")} {
    display: none;
  }
`;

export const MobileHeaderWrapper = styled.div`
  ${mediaMin("lg")} {
    display: none;
  }
`;

export const AlertWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ContentWrapper = styled.div`
  --padding-x: 16px;
  --max-width: 576px;

  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-width);
  flex: 1 1 auto;
  padding: 24px var(--padding-x) 0;

  ${mediaMin("lg")} {
    padding: 48px var(--padding-x) 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: var(--max-width);
  padding: 24px var(--padding-x) 48px;
  margin: 96px auto 0;

  ${mediaMax("lg")} {
    flex-direction: column-reverse;

    background: ${colors.flax}80;
    margin: 48px 0 0;
    width: auto;
    max-width: none;
    padding: 48px var(--padding-x) 120px;

    & > * {
      max-width: calc(var(--max-width) - 2 * var(--padding-x));
      margin: 0 auto;
    }
  }
`;

export const ChatWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: ${zIndex.modal};

  ${mediaMax("lg")} {
    bottom: auto;
    top: 90px;
  }
`;
