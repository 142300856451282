import { ProductName } from "types/general";

const FLOW_PARAM_PRODUCT_NAME_MAP = {
  ewa: ProductName.earnedWageAccess,
  tax: ProductName.taxRefundAdvance,
};

const storeProductParam = () => {
  const params = new URLSearchParams(window.location.search);
  const flow = params.get("flow");
  const productName = FLOW_PARAM_PRODUCT_NAME_MAP[flow];

  if (!productName) return [];

  sessionStorage.setItem("productName", productName);

  return []; // don't remove query param in case controller needs it
};

export default storeProductParam;
