import React from "react";
import Loading from "components/ui/Loading";
import UILabel from "components/ui/UILabel";
import ExpensesList from "components/shared/ExpensesList";
import { getDashboardOverview, getNotifications } from "store/api/apiSlice";
import useTaxReturnsWithAdvance from "utils/hooks/useTaxReturnsWithAdvance";
import LineItem from "./LineItem";
import SubItem from "./SubItem";
import { getAccounts } from "./utils";
import * as Styled from "./styled";
import usePayrollAction from "./usePayrollAction";

const AccountsOverview: React.FC = () => {
  const payrollAction = usePayrollAction();
  const { data: dashboard, isLoading: isLoadingDashboard } =
    getDashboardOverview();
  const { data: notifications } = getNotifications();
  const taxReturnsWithAdvance = useTaxReturnsWithAdvance();

  if (isLoadingDashboard) return <Loading />;

  const upcomingExpensesAmount = dashboard?.upcomingExpensesAmount || 0;
  const availableBankBalance = dashboard?.linkedBank?.amount || 0;

  const lineItems = getAccounts({
    dashboard,
    payrollAction,
    notifications,
    taxReturnsWithAdvance,
  });

  return (
    <Styled.LineItemsWrapper>
      {lineItems.map((item) => (
        <Styled.LineItemWrapper key={item.name}>
          <LineItem {...item} />

          {item.name === "bank" && (
            <Styled.SubItemWrapper>
              <SubItem
                title="Upcoming Bills"
                amount={upcomingExpensesAmount}
                isDebit
                status={
                  availableBankBalance >= upcomingExpensesAmount
                    ? "ok"
                    : "warning"
                }
                href="/recurring-expenses"
              />

              <Styled.SubItemBody>
                {availableBankBalance < upcomingExpensesAmount &&
                  upcomingExpensesAmount > 0 && (
                    <UILabel.Medium>
                      Your upcoming bills may cause an overdraft with your{" "}
                      {dashboard?.linkedBank?.name || "linked checking"}{" "}
                      account.
                    </UILabel.Medium>
                  )}

                <ExpensesList />
              </Styled.SubItemBody>
            </Styled.SubItemWrapper>
          )}
        </Styled.LineItemWrapper>
      ))}
    </Styled.LineItemsWrapper>
  );
};

export default AccountsOverview;
