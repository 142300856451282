import React from "react";
import styled from "styled-components";
import PartnerLogoLockup from "components/ui/PartnerLogoLockup";
import { colors, mediaMin } from "utils/theme";
import { getUserProfile } from "store/api/apiSlice";
import UILabel from "components/ui/UILabel";
import Logo from "components/ui/Logo";
import { getResetConstant } from "utils/reset";
import { getSessionPartner } from "utils/partners";
import { PartnerSlug } from "types/general";

const ResetLogo = styled(Logo)`
  width: 80px;

  opacity: 0.25;

  * {
    fill: ${colors.azure};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const LogoWrapper = styled.div`
  ${mediaMin("lg")} {
    display: none;
  }
`;

interface Props {
  withAuth?: boolean;
  hideDisclosure?: boolean;
}

const AppFooter: React.FC<Props> = ({ withAuth, hideDisclosure }) => {
  const { data: userProfile } = getUserProfile({ skip: !withAuth });

  const sessionPartner = getSessionPartner();
  const partnerSlug =
    userProfile?.partnerSlug || (sessionPartner?.slug as PartnerSlug);

  return (
    <Wrapper>
      <LogoWrapper>
        {partnerSlug ? (
          <PartnerLogoLockup partnerSlug={partnerSlug} />
        ) : (
          <ResetLogo />
        )}
      </LogoWrapper>

      {!hideDisclosure && (
        <UILabel.Small color={`${colors.midnight}80`}>
          {getResetConstant("disclosure")}
        </UILabel.Small>
      )}
    </Wrapper>
  );
};

export default AppFooter;
