import React from "react";
import { ButtonLevel, ButtonSize } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import relinkPayrollImage from "assets/images/general/relink-payroll.png";
import { ApiNotificationSource } from "utils/api/notifications";
import * as Styled from "../styled";
import { NotificationMessageProps, NotificationUi } from "../types";

const DEFAULT_ACCOUNTS = {
  linkedBank: "Your financial institution",
  payroll: "Your payroll provider",
};

const getAccountName = ({
  notification,
  dashboardOverview,
}: NotificationMessageProps) => {
  switch (notification.params.source) {
    case ApiNotificationSource.plaid:
      return dashboardOverview?.linkedBank?.name || DEFAULT_ACCOUNTS.linkedBank;
    case ApiNotificationSource.pinwheel:
      return dashboardOverview?.payroll?.name || DEFAULT_ACCOUNTS.payroll;
    default:
      return undefined;
  }
};

const getIntegrationErrorSuccessMessage = (props: NotificationMessageProps) =>
  `${getAccountName(props)} account reconnected!`;

const PINWHEEL_INTEGRATION_ERROR_NOTIFICATION: NotificationUi = {
  title: "Your earnings amount may not be up-to-date",
  message: (props) =>
    `${getAccountName(props)} requires you to reconnect periodically to access the latest income data`,
  image: relinkPayrollImage,
  actions: ({ onClick, onClickLearnMore }) => [
    <Styled.ActionButton
      onClick={onClick}
      size={ButtonSize.md}
      level={ButtonLevel.cta}
    >
      Reconnect
    </Styled.ActionButton>,
    <Styled.ActionLinkButton onClick={onClickLearnMore}>
      Learn More
    </Styled.ActionLinkButton>,
  ],
  modalMessage: (
    <>
      <UILabel.Small bold caps color="azure">
        How Reset works
      </UILabel.Small>
      <p>
        Reset relies on ongoing data about your income and employment to provide
        early access to your earnings. To make sure we have the most up-to-date
        information, we'll periodically ask you to reconnect to your payroll
        account. How often this is necessary depends on your specific payroll
        provider. Visit the Reset app daily to ensure the spending limit is
        fully updated!
      </p>
    </>
  ),
  successMessage: getIntegrationErrorSuccessMessage,
};

const PLAID_INTEGRATION_ERROR_NOTIFICATION: NotificationUi = {
  title: "Reconnect your checking account",
  message: (props) =>
    `${getAccountName(props)} requires you to reconnect periodically to keep your balance and transactions up-to-date`,
  icon: "bank",
  successMessage: getIntegrationErrorSuccessMessage,
};

export const getIntegrationErrorNotificationUi = (
  source?: ApiNotificationSource
) => {
  switch (source) {
    case ApiNotificationSource.plaid:
      return PLAID_INTEGRATION_ERROR_NOTIFICATION;
    case ApiNotificationSource.pinwheel:
      return PINWHEEL_INTEGRATION_ERROR_NOTIFICATION;
    default:
      return undefined;
  }
};
