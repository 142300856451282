import { IconName } from "components/ui/Icon";

export const REFUND_JURISDICTIONS: {
  title: string;
  icon: IconName;
  valueKey: string;
}[] = [
  {
    title: "Federal Refund",
    icon: "flagUsa",
    valueKey: "federal",
  },
  {
    title: "State Refund",
    icon: "locationDot",
    valueKey: "state",
  },
];
