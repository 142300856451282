import DisappearingTile from "components/ui/DisappearingTile";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ContentTile = styled(DisappearingTile).attrs(() => ({
  size: "xl",
}))`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`;
