import React from "react";
import { useSearchParams } from "react-router-dom";
import { getAdminWaitlist } from "store/admin/api/apiSlice";
import Loading from "components/ui/Loading";
import Icon from "components/ui/Icon";
import { colors } from "utils/theme";
import Pagination from "components/ui/Pagination";
import {
  ApiAdminWaitlistEligibility,
  ApiAdminWaitlistEntry,
} from "utils/api/admin/waitlist";
import { formatDateString } from "utils/date";
import Table from "../../components/Table";
import EligibilityTag from "../../components/EligibilityTag";
import * as Styled from "./styled";

const EligibilityIcon = ({
  eligibility,
}: {
  eligibility: ApiAdminWaitlistEligibility;
}) => {
  switch (eligibility) {
    case "red":
      return (
        <Icon
          name="statusDanger"
          color="white"
          colorSecondary={colors.cerise}
          size="sm"
        />
      );
    case "yellow":
      return (
        <Icon name="statusWarning" colorSecondary={colors.cosmos} size="sm" />
      );
    case "green":
      return (
        <Icon name="statusSuccess" colorSecondary={colors.leaf} size="sm" />
      );
    default:
      return null;
  }
};

const HEADINGS = [
  "Name",
  "Email",
  "Bank Account",
  "Payroll Provider",
  "Income Type",
  "T&A Platform",
  "Eligibility",
  "Created At",
];

const getRow = (entry: ApiAdminWaitlistEntry) => [
  entry.name,
  entry.email,
  <Styled.TextWithTooltip
    data-tooltip={
      entry.bankAccountJobs?.length ? entry.bankAccountJobs : undefined
    }
  >
    <EligibilityIcon eligibility={entry.bankAccountEligible} />
    {entry.bankAccountName}
  </Styled.TextWithTooltip>,
  <Styled.TextWithTooltip
    data-tooltip={
      entry.payrollProviderJobs?.length ? entry.payrollProviderJobs : undefined
    }
  >
    <EligibilityIcon eligibility={entry.payrollProviderEligible} />
    {entry.payrollProviderName}
  </Styled.TextWithTooltip>,
  entry.incomeType,
  entry.timeAndAttendancePlatformName,
  <EligibilityTag status={entry.eligible} />,
  formatDateString(entry.createdAt, "numericShortWithTime"),
];

const Waitlists = () => {
  const [params] = useSearchParams();
  const page = parseInt(params.get("page"), 10) || 1;
  const { data, isLoading } = getAdminWaitlist(page);

  if (isLoading) <Loading />;

  const { entries, totalPages } = data || {};

  if (!entries) return null;

  return (
    <Styled.Wrapper>
      <Table>
        <thead>
          <tr>
            {HEADINGS.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {entries.map((entry) => (
            <tr key={entry.id}>
              {getRow(entry).map((cell, index) => (
                <td key={index} data-heading={HEADINGS[index]}>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {totalPages > 1 && <Pagination current={page} total={totalPages} />}
    </Styled.Wrapper>
  );
};

export default Waitlists;
