import { OnboardingState } from "types/onboarding";

export const ACCOUNT_SETUP_STATES = [
  OnboardingState.unregistered,
  OnboardingState.registered,
  OnboardingState.linkedCoreFinancialInstitutionAccount,
  OnboardingState.linkedIncomeAccount,
  OnboardingState.skippedIncomeAccountLinking,
  OnboardingState.incomeAccountLinkingUnsupported,
];

export const CARDHOLDER_STATES = [
  OnboardingState.issuedCard,
  OnboardingState.activatedCard,
  OnboardingState.usedCard,
];
