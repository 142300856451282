import React from "react";
import PasswordFields from "components/shared/PasswordFields";
import ProductOnboardingFlow from "components/shared/ProductOnboarding/Flow";
import SiteLayout from "components/layout/SiteLayout";
import CenteredLayout from "components/layout/CenteredLayout";
import { PartnerSlug, ProductName } from "types/general";
import App from "App";

const EditPassword = (props) => {
  const params = new URLSearchParams(window.location.search);
  const isSignup = params.get("signup");

  if (!isSignup)
    return (
      <SiteLayout>
        <CenteredLayout>
          <PasswordFields {...props} />
        </CenteredLayout>
      </SiteLayout>
    );

  const partnerSlug = sessionStorage.getItem("partner") as PartnerSlug;
  const productName = sessionStorage.getItem("productName") as ProductName;

  return (
    <App>
      <ProductOnboardingFlow
        partnerSlug={partnerSlug}
        productName={productName}
        showChatWidget
      >
        <PasswordFields {...props} isSignup />
      </ProductOnboardingFlow>
    </App>
  );
};

export default EditPassword;
