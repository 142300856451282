import { ApiDashboardOverview } from "utils/api/dashboard";
import { ApiNotification, ApiNotificationType } from "utils/api/notifications";
import { hasDslUpdatingNotification } from "utils/notifications";
import { TaxReturnsWithAdvance } from "utils/hooks/useTaxReturnsWithAdvance";
import {
  DashboardAccount,
  DashboardAccountAction,
  DashboardAccountStatus,
} from "./types";
import { API_BANK_NAME_TO_DISPLAY_NAME_MAP } from "./constants";

const ERROR_TYPES = [ApiNotificationType.integrationError];

const hasErrorNotifications = (
  source: string,
  notifications: ApiNotification[] = []
) =>
  notifications.some(
    (notification: ApiNotification) =>
      ERROR_TYPES.includes(notification.type) &&
      notification.params?.source === source
  );

type AccountStatusGetter = (props: {
  notifications: ApiNotification[];
}) => DashboardAccountStatus;

const getPayrollAccountStatus: AccountStatusGetter = ({
  notifications = [],
}) => {
  if (hasErrorNotifications("pinwheel", notifications)) {
    return DashboardAccountStatus.inactive;
  }
  if (hasDslUpdatingNotification(notifications)) {
    return DashboardAccountStatus.pending;
  }
  return DashboardAccountStatus.active;
};

const getBankAccountStatus: AccountStatusGetter = ({ notifications = [] }) => {
  if (hasErrorNotifications("plaid", notifications)) {
    return DashboardAccountStatus.inactive;
  }
  if (hasDslUpdatingNotification(notifications)) {
    return DashboardAccountStatus.pending;
  }
  return DashboardAccountStatus.active;
};

const mapBankToDisplayName = (bankName: string) =>
  API_BANK_NAME_TO_DISPLAY_NAME_MAP[bankName] || bankName;

interface AccountProps {
  dashboard: ApiDashboardOverview;
  payrollAction: DashboardAccountAction;
  notifications: ApiNotification[];
  taxReturnsWithAdvance: TaxReturnsWithAdvance;
}

const getPayrollAccount: (props: AccountProps) => DashboardAccount = ({
  dashboard,
  notifications,
  payrollAction,
}) => ({
  name: "earnings",
  title: "Accrued Net Earnings",
  subtitle: dashboard?.payroll?.name || "Your Income Source",
  amount: dashboard?.payroll?.amount || 0,
  icon: "dollar",
  href: "/income",
  status: getPayrollAccountStatus({ notifications }),
  actions: [payrollAction],
});

const getBankAccount: (props: AccountProps) => DashboardAccount = ({
  dashboard,
  notifications,
}) => ({
  name: "bank",
  title: "Available Balance",
  subtitle:
    mapBankToDisplayName(dashboard?.linkedBank?.name) ||
    "Your Checking Account",
  amount: dashboard?.linkedBank?.amount || 0,
  icon: "piggyBank",
  href: "/recurring-expenses",
  status: getBankAccountStatus({ notifications }),
});

const getCardAccount: (props: AccountProps) => DashboardAccount = ({
  dashboard,
}) => ({
  name: "card",
  title: "Outstanding Transactions",
  subtitle: "Reset Card",
  amount: dashboard?.resetCard?.amount || 0,
  icon: "creditCard",
  href: "/card-transactions",
  isDebit: true,
});

const getTaxRefundAccount: (props: {
  amount: number;
  status: DashboardAccountStatus;
}) => DashboardAccount = ({ amount, status }) => ({
  name: "tax",
  title: "Tax Refund",
  subtitle: "Federal",
  amount,
  status,
  icon: "check",
  isDebit: false,
});

export const getAccounts: (props: AccountProps) => DashboardAccount[] = (
  props
) => {
  const bankAccount = getBankAccount(props);
  const hasCard = !!props.dashboard?.resetCard;
  const hasPayroll = !!props.dashboard?.payroll;
  const hasTaxRefund =
    !!props.dashboard?.taxRefund ||
    props.taxReturnsWithAdvance.hasEligibleReturns;

  const items: DashboardAccount[] = [];

  if (hasTaxRefund) {
    // Only accepted returns factor into the DSL, but we want to display any pending refund, so we need to look at both
    const amount =
      props.dashboard?.taxRefund?.amount ||
      props.taxReturnsWithAdvance.refundAdvanceAmount;
    const status = props.taxReturnsWithAdvance.isAvailable
      ? null
      : DashboardAccountStatus.pendingRefund;
    const taxRefundAccount = getTaxRefundAccount({ amount, status });
    items.push(taxRefundAccount);
  }

  if (hasPayroll) {
    const payrollAccount = getPayrollAccount(props);
    items.push(payrollAccount);
  }

  items.push(bankAccount);

  if (hasCard) {
    const cardAccount = getCardAccount(props);
    items.push(cardAccount);
  }

  return items;
};
