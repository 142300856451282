import { IconName } from "components/ui/Icon";

export enum DashboardAccountStatus {
  active = "ACTIVE",
  inactive = "INACTIVE",
  pending = "PENDING",
  pendingRefund = "PENDING_REFUND",
}

export interface DashboardAccountAction {
  onClick: () => void;
  title: string;
  icon?: IconName;
  isLoading?: boolean;
  disabled?: boolean;
  toRender?: React.ReactNode;
}

export interface DashboardAccount {
  name: string;
  title: string;
  amount: number;
  subtitle?: string;
  icon?: IconName;
  isDebit?: boolean;
  href?: string;
  status?: DashboardAccountStatus;
  actions?: DashboardAccountAction[];
}
