import React from "react";
import ProductOnboardingFlow from "components/shared/ProductOnboarding/Flow";
import {
  getTaxRefundAdvanceApplicationStatus,
  getUserProfile,
} from "store/api/apiSlice";
import { ProductName } from "types/general";
import Step from "./Step";

const Flow: React.FC = () => {
  const { data: taxTaxRefundAdvanceApplicationStatus } =
    getTaxRefundAdvanceApplicationStatus();
  const { data: userProfile } = getUserProfile();

  if (!taxTaxRefundAdvanceApplicationStatus) return null;

  const partnerSlug = userProfile?.partnerSlug;

  return (
    <ProductOnboardingFlow
      tasks={taxTaxRefundAdvanceApplicationStatus.tasks}
      productName={ProductName.taxRefundAdvance}
      partnerSlug={partnerSlug}
      withAuth
    >
      {({ showInfo }) => <Step showInfo={showInfo} partnerSlug={partnerSlug} />}
    </ProductOnboardingFlow>
  );
};

export default Flow;
