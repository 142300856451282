import React from "react";
import { useFormContext } from "react-hook-form";
import { SubmitButton } from "components/form";
import PersonalInfoList, { Info } from "components/ui/PersonalInfoList";
import FormContentLayout from "components/layout/FormContentLayout";
import { LinkButton } from "components/ui/Link";
import { ButtonLevel } from "components/ui/Button";
import { ApiUserProfile } from "utils/api/user";

interface Props {
  onClickBack: () => void;
  user: ApiUserProfile;
}

const ReviewInfo: React.FC<Props> = ({
  user: { firstName, lastName },
  onClickBack,
}) => {
  const { watch } = useFormContext();
  const personalInfo = watch() as Info;

  return (
    <FormContentLayout
      heading="Let's review your info."
      subheading="Please verify that the information below is correct before submitting."
      actions={
        <SubmitButton
          name="confirmed"
          value={true}
          fullWidth
          level={ButtonLevel.cta}
        >
          Verify Identity
        </SubmitButton>
      }
      footer={
        <LinkButton onClick={onClickBack} color="azure" size="lg">
          I need to make a change
        </LinkButton>
      }
    >
      <PersonalInfoList info={{ firstName, lastName, ...personalInfo }} />
    </FormContentLayout>
  );
};

export default ReviewInfo;
