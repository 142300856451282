import React from "react";
import { Body } from "components/ui/Text";
import Icon from "components/ui/Icon";
import { ButtonLink, ButtonSize } from "components/ui/Button";
import FormContentLayout from "components/layout/FormContentLayout";

const PendingMessage = () => (
  <FormContentLayout
    heading="Thanks for linking your accounts!"
    actions={
      <ButtonLink href="/dashboard" size={ButtonSize.lg}>
        Explore My Dashboard
        <Icon name="arrowRight" />
      </ButtonLink>
    }
    useTile
  >
    <Body>
      We need to verify your account data before you can proceed with the{" "}
      <strong>Reset card</strong> application. This typically takes
      less&nbsp;than an&nbsp;hour.
    </Body>
    <Body>
      In the meantime you&apos;re free to explore your Dashboard, which allows
      you to track your earned income, checking account balance, and upcoming
      expenses.
    </Body>
  </FormContentLayout>
);

export default PendingMessage;
