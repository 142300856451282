import React from "react";
import { IconName } from "components/ui/Icon";
import { PartnerSlug, ProductName } from "types/general";
import * as Styled from "./styled";

type IntroText = React.ReactNode | ((props: any) => React.ReactNode);

interface IntroItem {
  icon: IconName;
  title: IntroText;
  description: IntroText;
  isComplete?: boolean;
}

const SIGNUP_ITEM: IntroItem = {
  title: "Sign up",
  description: "Create a Reset account.",
  icon: "userCircle",
};

const getTaxFilingItemTitle = ({ partnerSlug }) => {
  if (partnerSlug === PartnerSlug.msufcu) {
    return (
      <Styled.ItemTitle>
        File your taxes <Styled.ItemTag>Free!</Styled.ItemTag>
      </Styled.ItemTitle>
    );
  }

  return "File your taxes";
};

const getTaxFilingItemDescription = ({ partnerSlug }) => {
  if (partnerSlug === PartnerSlug.msufcu) {
    return (
      <>
        Get 100% free tax filing if you receive a tax refund and direct deposit
        it to MSUFCU! Otherwise just pay a flat $20&mdash;no upsells or
        hidden&nbsp;fees!
      </>
    );
  }

  if (Object.values(PartnerSlug).includes(partnerSlug)) {
    return "Only pay a flat $20, and get a full credit on your filing if you receive a tax refund advance. No upsells or hidden fees!";
  }

  return <>Pay a flat $20&mdash;no upsells or hidden fees!</>;
};

export const DEFAULT_INTRO_ITEMS: IntroItem[] = [
  SIGNUP_ITEM,
  {
    title: "Connect your checking account",
    description: "Securely connect your account with Plaid.",
    icon: "bank",
  },
  {
    title: "Verify your income",
    description: "Connect your income source in seconds.",
    icon: "income",
  },
  {
    title: "Submit your application",
    description: "Verify your identity to complete your application.",
    icon: "clipboardListCheck",
  },
];

const TAX_REFUND_ADVANCE_INTRO_ITEMS: IntroItem[] = [
  SIGNUP_ITEM,
  {
    title: "Connect your checking account",
    description: "Securely connect your account with Plaid.",
    icon: "bank",
  },
  {
    title: "Verify personal information",
    description: "Answer a few basic questions about yourself.",
    icon: "clipboardListCheck",
  },
  {
    title: getTaxFilingItemTitle,
    description: getTaxFilingItemDescription,
    icon: "calculator",
  },
];

export const DEFAULT_INTRO = {
  items: DEFAULT_INTRO_ITEMS,
  heading: "Get daily access to your earned income in minutes.",
  subheading: "No interest, instant transfer fees, monthly fees, or late fees.",
};

const TAX_REFUND_ADVANCE_INTRO = {
  items: TAX_REFUND_ADVANCE_INTRO_ITEMS,
  heading: "Get access to your refund as soon as your return is accepted.",
  subheading:
    "Start putting your tax refund to work up to 21 days early with a tax refund advance. No credit check, interest, or fees for advances.",
};

export const INTRO: {
  [key in ProductName]: {
    items: IntroItem[];
    heading: IntroText;
    subheading?: IntroText;
  };
} = {
  [ProductName.earnedWageAccess]: DEFAULT_INTRO,
  [ProductName.taxRefundAdvance]: TAX_REFUND_ADVANCE_INTRO,
};
