import React, { useEffect } from "react";
import FormContentLayout from "components/layout/FormContentLayout";
import FormContainer, { FormSection } from "components/form";
import Button, { ButtonLevel } from "components/ui/Button";
import BankAccount from "components/shared/BankAccount";
import Loading from "components/ui/Loading";
import {
  getBankAccountsLinked,
  usePostCardApplicationConfirmRepaymentAccountMutation,
} from "store/api/apiSlice";
import CancelApplicationButton from "../CancelApplicationButton";

interface Props {
  onSuccess: () => void;
}

const ConfirmRepaymentAccount: React.FC<Props> = ({ onSuccess }) => {
  const { data: linkedBankAccount, isLoading: isLoadingBankAccount } =
    getBankAccountsLinked();
  const [
    postCardApplicationConfirmRepaymentAccount,
    { isLoading: isConfirming, isSuccess: isConfirmed },
  ] = usePostCardApplicationConfirmRepaymentAccountMutation();

  const onSubmit = async () => {
    await postCardApplicationConfirmRepaymentAccount();
  };

  useEffect(() => {
    if (isConfirmed) {
      onSuccess();
    }
  }, [isConfirmed, onSuccess]);

  return (
    <FormContainer onSubmit={onSubmit}>
      <FormContentLayout
        heading="Set up autopay."
        subheading={
          <>
            Please verify that the information below is correct before
            submitting. This is the checking account Reset will use for
            repayment.
          </>
        }
        actions={
          <Button
            isLoading={isConfirming || isConfirmed}
            type="submit"
            level={ButtonLevel.cta}
          >
            Confirm
          </Button>
        }
        footer={<CancelApplicationButton />}
      >
        <FormSection>
          {isLoadingBankAccount ? (
            <Loading />
          ) : (
            <BankAccount bankAccount={linkedBankAccount} />
          )}
        </FormSection>
      </FormContentLayout>
    </FormContainer>
  );
};

export default ConfirmRepaymentAccount;
