import React, { useEffect, useState } from "react";
import { Body } from "components/ui/Text";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import {
  getUserProfile,
  usePostSendConfirmationInstructionsForCurrentUserMutation,
} from "store/api/apiSlice";
import Icon from "components/ui/Icon";
import FormContentLayout from "components/layout/FormContentLayout";

const UnconfirmedMessage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [postResendConfirmation, postResendConfirmationContext] =
    usePostSendConfirmationInstructionsForCurrentUserMutation();
  const { data: userProfile } = getUserProfile({
    pollingInterval: isSuccess ? 2_000 : undefined,
  });

  const resendConfirmation = async () => {
    await postResendConfirmation();
  };

  useEffect(() => {
    if (postResendConfirmationContext.isSuccess) {
      setIsSuccess(true);
    }
  }, [postResendConfirmationContext.isSuccess]);

  return (
    <FormContentLayout
      heading={<>Please confirm your&nbsp;email address.</>}
      actions={
        <Button
          onClick={resendConfirmation}
          size={ButtonSize.lg}
          level={ButtonLevel.cta}
          isLoading={postResendConfirmationContext.isLoading}
          disabled={isSuccess}
        >
          {isSuccess ? (
            <>
              <Icon name="checkmark" />
              Email Sent
            </>
          ) : (
            "Resend Confirmation Email"
          )}
        </Button>
      }
      useTile
    >
      <Body>
        We emailed a link to <strong>{userProfile?.email}</strong>. You must
        click it to verify your email address before proceeding. If you don't
        see the email in your inbox, please check your spam folder.
      </Body>
    </FormContentLayout>
  );
};

export default UnconfirmedMessage;
