import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import styled from "styled-components";
import FormContainer, { SelectField, SubmitButton } from "components/form";
import { Body, Heading } from "components/ui/Text";
import { usePostAdminExportDataMutation } from "store/admin/api/apiSlice";
import { ApiDataExportType } from "utils/api/admin";
import Modal, { ModalApi } from "./Modal";

export const DATA_TYPE_OPTIONS = [
  { name: "Users", value: ApiDataExportType.users },
  { name: "Waitlist", value: ApiDataExportType.waitlist },
  { name: "Card accounts", value: ApiDataExportType.cardAccounts },
  { name: "Card usage", value: ApiDataExportType.cardUsage },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

const AdminExportDataModal = (
  _: {},
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postExportData, postExportDataContext] =
    usePostAdminExportDataMutation();

  const onSubmit = (values: { type: ApiDataExportType }) => {
    postExportData(values);
  };

  useEffect(() => {
    ref.current?.dismiss();
  }, [postExportDataContext.isSuccess]);

  return (
    <Modal ref={ref}>
      <Wrapper>
        <Heading.H4 tag="h2">Export admin data</Heading.H4>

        <Body>You will receive an email with the data export attached.</Body>

        <FormContainer onSubmit={onSubmit}>
          <SelectField
            name="type"
            label="Data type"
            options={DATA_TYPE_OPTIONS}
            defaultValue={ApiDataExportType.users}
            required
          />

          <SubmitButton>Export</SubmitButton>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(AdminExportDataModal);
