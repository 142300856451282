import React from "react";
import ProductOnboardingFlow from "components/shared/ProductOnboarding/Flow";
import Signup from "components/shared/ProductOnboarding/Signup";
import { PartnerSlug, ProductName } from "types/general";

const Flow: React.FC = () => {
  const partnerSlug = sessionStorage.getItem("partner") as PartnerSlug;
  const productName = sessionStorage.getItem("productName") as ProductName;

  return (
    <ProductOnboardingFlow
      productName={productName}
      partnerSlug={partnerSlug}
      showChatWidget
    >
      <Signup productName={productName} partnerSlug={partnerSlug} />
    </ProductOnboardingFlow>
  );
};

export default Flow;
