import React from "react";
import { IconListItem } from "components/ui/IconList";
import { PartnerSlug, ProductName } from "types/general";

type RenderText = React.ReactNode | ((props: any) => React.ReactNode);

const DEFAULT_LIST_ITEMS: IconListItem[] = [
  {
    icon: "arrowProgress",
    title: "Why connect my checking account?",
    body: "Reset factors in your checking account balance and upcoming bills to create a dynamic spending limit tailored to you.",
  },
  {
    icon: "ballotCheck",
    title: "What if I have multiple accounts?",
    body: (
      <>
        Please choose your{" "}
        <em>
          <strong>primary</strong>
        </em>{" "}
        checking account, where the majority of your direct deposit goes.
      </>
    ),
  },
  {
    icon: "shield",
    title: "Is it safe?",
    body: "Reset uses Plaid to securely connect your checking account and keep your data private.",
  },
];

const TAX_REFUND_ADVANCE_LIST_ITEMS: IconListItem[] = [
  {
    icon: "arrowProgress",
    title: "Why connect my checking account?",
    body: "Connecting your account makes it simple to deposit and access your tax refund.",
  },
  {
    icon: "shield",
    title: "Is it safe?",
    body: "Reset uses Plaid to securely connect your checking account and keep your data private.",
  },
];

interface Content {
  heading: RenderText;
  subheading?: RenderText;
  items: IconListItem[];
}

export const DEFAULT_CONTENT: Content = {
  heading: "Let's connect your checking account.",
  items: DEFAULT_LIST_ITEMS,
};

const getTaxHeading = ({ partnerSlug }) => {
  if (partnerSlug === PartnerSlug.msufcu) {
    return "Let's connect your MSUFCU account.";
  }

  return "Let's connect your checking account.";
};

const getTaxSubheading = ({ partnerSlug }) => {
  if (partnerSlug === PartnerSlug.msufcu) {
    return "It's free, easy, and allows Reset to give you early access to your refund. Select your MSUFCU account to file your taxes for free!";
  }

  return "It's free, easy, and allows Reset to give you early access to your refund.";
};

const TAX_REFUND_ADVANCE_CONTENT: Content = {
  heading: getTaxHeading,
  subheading: getTaxSubheading,
  items: TAX_REFUND_ADVANCE_LIST_ITEMS,
};

export const CONTENT: { [key in ProductName]: Content } = {
  [ProductName.earnedWageAccess]: DEFAULT_CONTENT,
  [ProductName.taxRefundAdvance]: TAX_REFUND_ADVANCE_CONTENT,
};
