import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import useApril from "utils/hooks/useApril";
import Modal, { ModalApi } from "../Modal";

const ELEMENT_ID = "april-container";

const Wrapper = styled.div`
  height: calc(100vh - 144px);
  margin: -64px -48px;
  border-radius: 24px;
  overflow: hidden;

  iframe {
    display: block;
  }
`;

const AprilModal = (
  { onClose }: { onClose?: () => void },
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);

  const onExit = () => {
    ref.current?.dismiss();
  };

  const april = useApril({ elementId: ELEMENT_ID, onExit });

  const show = () => {
    april.start();
    ref.current?.show();
  };

  useImperativeHandle(forwardedRef, () => ({ ...ref.current, show }));

  return (
    <Modal ref={ref} onClose={onClose} overrideCloseBehavior>
      <Wrapper id={ELEMENT_ID} />
    </Modal>
  );
};

export default forwardRef(AprilModal);
