import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button, { BaseButton, ButtonLevel } from "components/ui/Button";
import FormContainer, { CheckboxField, FormSection } from "components/form";
import FormContentLayout from "components/layout/FormContentLayout";
import TextWithLinks from "components/ui/TextWithLinks";
import InfoBox from "components/ui/InfoBox";
import UILabel from "components/ui/UILabel";
import { getDisclosures, usePostAcceptancesMutation } from "store/api/apiSlice";
import { ApiDisclosure } from "utils/api/disclosures";
import { ProductName } from "types/general";
import { InfoType } from "../types";
import { getFields } from "./fields";

const InfoButton = styled(BaseButton)`
  text-align: left;

  u {
    float: right;
  }
`;

const getCheckedDisclosureIds = (
  values: { [key: string]: string | boolean },
  disclosures: ApiDisclosure[]
) =>
  Object.values(values)
    .filter((checked) => checked)
    .reduce((acceptances: string[], checkboxAcceptances: string) => {
      const acceptedDisclosureTypes = checkboxAcceptances.split(",");
      const acceptedDisclosureIds = acceptedDisclosureTypes.map(
        (acceptedDisclosureType) => {
          const disclosure = disclosures.find(
            (d) => d.disclosureType === acceptedDisclosureType
          );

          return disclosure.id;
        }
      );

      return [...acceptances, ...acceptedDisclosureIds];
    }, []);

interface Props {
  showInfo: (infoType: InfoType) => () => void;
  productName?: ProductName;
}

const TermsAcceptance: React.FC<Props> = ({ showInfo, productName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: disclosuresData, isLoading: isLoadingDisclosures } =
    getDisclosures();
  const disclosureFields = getFields(disclosuresData);
  const [postAcceptances, { data: acceptanceResponseData }] =
    usePostAcceptancesMutation();

  const onSubmit = async (values) => {
    if (!disclosuresData?.disclosures) return;

    setIsLoading(true);

    const checkedDisclosures = getCheckedDisclosureIds(
      values,
      disclosuresData.disclosures
    );

    await postAcceptances({
      disclosures: checkedDisclosures,
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if (acceptanceResponseData) {
      window.location.reload();
    }
  }, [acceptanceResponseData]);

  return (
    <FormContainer onSubmit={onSubmit}>
      <FormContentLayout
        heading="Please accept the following terms to continue."
        actions={
          <Button
            isLoading={isLoading}
            disabled={isLoadingDisclosures}
            type="submit"
            level={ButtonLevel.cta}
          >
            Agree & Continue
          </Button>
        }
      >
        <InfoButton onClick={showInfo(InfoType.patriotAct)} type="button">
          <InfoBox>
            <UILabel.Small caps>
              IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT{" "}
              <strong>
                <u>Read more</u>
              </strong>
            </UILabel.Small>
          </InfoBox>
        </InfoButton>

        {!isLoadingDisclosures && (
          <FormSection>
            {disclosureFields.map((item) => (
              <CheckboxField
                key={item.name}
                name={item.name}
                value={item.value}
                label={<TextWithLinks nodes={item.label} />}
                required
              />
            ))}
          </FormSection>
        )}

        {productName === ProductName.taxRefundAdvance && (
          <UILabel.Medium color="dust">
            When you sign up for a Reset account, you get access to all our
            services, including tax filing, a Reset card, and the option for a
            tax refund advance if you are eligible—but you’re not required to
            use all of these services. You can choose to file your taxes only.
            We provide all terms upfront for transparency, so you don’t have to
            agree to new terms each time you use a different service. You remain
            in control of which Reset products you use and when.
          </UILabel.Medium>
        )}
      </FormContentLayout>
    </FormContainer>
  );
};

export default TermsAcceptance;
