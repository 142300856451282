import React, { useRef } from "react";
import Alert from "components/ui/Alert";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import PlaidModal, {
  ModalApi as PlaidModalApi,
} from "components/shared/PlaidModal";
import { getDashboardOverview } from "store/api/apiSlice";
import { getIntegrationErrorMessage } from "utils/notifications";
import {
  ApiNotification,
  ApiNotificationErrorType,
  ApiNotificationSource,
} from "utils/api/notifications";
import styled from "styled-components";

const List = styled.ul`
  display: grid;
  gap: 16px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    display: block;
    width: 100%;
  }
`;

interface Props {
  errors: ApiNotification[];
  onSuccess: () => void;
  render?: (errors: ApiNotification[]) => React.ReactNode;
}

const IntegrationErrors: React.FC<Props> = ({ errors, onSuccess, render }) => {
  const pinwheelModalRef = useRef<PinwheelModalApi>(null);
  const plaidModalRef = useRef<PlaidModalApi>(null);
  const { data: dashboardOverview } = getDashboardOverview();

  const pinwheelRelinkHandler = () => {
    if (pinwheelModalRef && pinwheelModalRef.current) {
      pinwheelModalRef.current.open();
    }
  };

  const plaidRelinkHandler = () => {
    if (plaidModalRef && plaidModalRef.current) {
      plaidModalRef.current.open();
    }
  };

  const mapNotificationToHandler = async (
    integrationError: ApiNotification
  ) => {
    if (
      integrationError.params.errorType ===
      ApiNotificationErrorType.monitoring_status_unavailable
    ) {
      switch (integrationError.params.source) {
        case ApiNotificationSource.pinwheel: {
          pinwheelRelinkHandler();
          break;
        }
        case ApiNotificationSource.plaid: {
          plaidRelinkHandler();
          break;
        }
        default:
          break;
      }
    }
  };

  if (errors.length === 0) return null;

  return (
    <>
      {render ? (
        render(errors)
      ) : (
        <List>
          {errors.map((integrationError) => (
            <li key={integrationError.id}>
              <Alert
                onClick={(e) => {
                  e.preventDefault();
                  mapNotificationToHandler(integrationError);
                }}
              >
                {getIntegrationErrorMessage(
                  integrationError,
                  dashboardOverview
                )}
              </Alert>
            </li>
          ))}
        </List>
      )}

      <PinwheelModal ref={pinwheelModalRef} onSuccess={onSuccess} />

      <PlaidModal ref={plaidModalRef} onSuccess={onSuccess} />
    </>
  );
};

export default IntegrationErrors;
