import React from "react";
import { Body } from "components/ui/Text";
import Button from "components/ui/Button";
import FormContentLayout from "components/layout/FormContentLayout";

interface Props {
  isEligible: boolean;
}

const WaitlistMessage: React.FC<Props> = ({ isEligible }) => {
  const onClickConnect = () => {
    window.location.reload();
  };

  return (
    <FormContentLayout
      useTile
      heading="Thank you for your interest in Reset."
      actions={
        isEligible
          ? [<Button onClick={onClickConnect}>Connect Payroll Account</Button>]
          : undefined
      }
    >
      <Body>
        {isEligible ? (
          <>
            Our records indicate that your payroll provider may be supported.
            You can click below to try connecting to your payroll account, or{" "}
            <a href="mailto:hello@getreset.co?subject=Help linking payroll">
              contact us
            </a>{" "}
            for assistance.
          </>
        ) : (
          <>
            Upon reviewing your linked accounts, they do not currently meet our
            eligibility criteria. Unfortunately, we're unable to move your
            application forward at this&nbsp;time.
          </>
        )}
      </Body>
    </FormContentLayout>
  );
};

export default WaitlistMessage;
