export enum FormStepStatus {
  incomplete = "incomplete",
  ready = "ready",
  complete = "complete",
}

export interface FormStep {
  id?: string;
  title: string;
  description?: React.ReactNode;
  status: FormStepStatus;
}
