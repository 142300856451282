import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import startApril, { SDKEnvironmentType } from "@april/web-sdk";
import { getAprilAccessToken } from "store/api/apiSlice";

interface Props {
  elementId: string;
  onExit: () => void;
}

const useApril = ({ elementId, onExit }: Props) => {
  const [sessionId, setSessionId] = useState<null | string>(null);
  const { data, refetch } = getAprilAccessToken({ skip: !sessionId });

  const onAprilExit = () => {
    setSessionId(null);
    onExit();
  };

  useEffect(() => {
    if (data?.accessToken) {
      startApril(
        {
          applicationContext: {
            authToken: data.accessToken,
            sdkEnvironment: SDKEnvironmentType.SANDBOX,
          },
          renderInElement: `#${elementId}`,
        },
        () => {
          onAprilExit();
        },
        () => {
          refetch();
        }
      );
    }
  }, [data?.accessToken]);

  useEffect(() => {
    if (sessionId) {
      refetch();
    }
  }, [sessionId]);

  const start = () => {
    setSessionId(uuid());
  };

  return { start };
};

export default useApril;
