import { IncomeType } from "types/signup";

export interface SearchInputSelection {
  id: string;
  searchString: string;
}

export interface SignupSelections {
  bank?: SearchInputSelection;
  payroll?: SearchInputSelection;
  incomeType?: IncomeType;
}

export enum PartnerSlug {
  msufcu = "msufcu",
  cusg = "cusg",
}

export enum ProductName {
  earnedWageAccess = "earned_wage_access",
  taxRefundAdvance = "tax_refund_advance",
}
