import React from "react";
import IntegrationErrors from "components/shared/IntegrationErrors";
import FormContentLayout from "components/layout/FormContentLayout";
import { ApiNotification } from "utils/api/notifications";

interface Props {
  integrationErrors: ApiNotification[];
  refetch: () => void;
}

const IntegrationErrorResolution: React.FC<Props> = ({
  integrationErrors,
  refetch,
}) => {
  const onRelinkSuccess = () => {
    refetch();
  };

  return (
    <FormContentLayout
      heading="Please resolve the following issues before proceeding with your
        application."
    >
      <IntegrationErrors
        errors={integrationErrors}
        onSuccess={onRelinkSuccess}
      />
    </FormContentLayout>
  );
};

export default IntegrationErrorResolution;
