import React from "react";
import { Eyebrow } from "components/ui/Text";
import Icon from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import FormContainer, {
  MaskedField,
  MaskType,
  SubmitButton,
} from "components/form";
import { formatPhoneNumber } from "utils/string";
import socureLogo from "assets/images/general/socure-logo.svg";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import * as Styled from "./styled";

interface Props {
  onSubmitPhoneNumber: (values: any) => void;
  phoneNumber?: string;
}

const SendLink: React.FC<Props> = ({
  onSubmitPhoneNumber,
  phoneNumber = "",
}) => (
  <Styled.Wrapper>
    <Styled.Overview>
      <Eyebrow>What you'll need</Eyebrow>

      <Styled.OverviewItems>
        <Styled.OverviewItem>
          <Styled.OverviewIcon>
            <Icon name="idCard" size="xl" />
          </Styled.OverviewIcon>
          <UILabel.Medium>Government-issued ID</UILabel.Medium>
        </Styled.OverviewItem>

        <Styled.OverviewItem>
          <Styled.OverviewIcon>
            <Icon name="phone" size="xl" />
          </Styled.OverviewIcon>
          <UILabel.Medium>Smart phone</UILabel.Medium>
        </Styled.OverviewItem>
      </Styled.OverviewItems>
    </Styled.Overview>

    <FormContainer
      onSubmit={onSubmitPhoneNumber}
      defaultValues={{
        phoneNumber: formatPhoneNumber(phoneNumber),
      }}
    >
      <Styled.Field>
        <MaskedField
          maskType={MaskType.phone}
          placeholder="(123) 456-7890"
          name="phoneNumber"
          label="Phone number"
          required
        />

        <UILabel.Small color="dust">
          Standard messaging and data rates may apply.
        </UILabel.Small>
      </Styled.Field>

      <MobileFixedFooter>
        <SubmitButton>Send Link</SubmitButton>
      </MobileFixedFooter>
    </FormContainer>

    <Styled.SocureLogo>
      <UILabel.Small>powered by</UILabel.Small>
      <img src={socureLogo} alt="Socure" />
    </Styled.SocureLogo>
  </Styled.Wrapper>
);

export default SendLink;
