import React, { useRef } from "react";
import UILabel from "components/ui/UILabel";
import TextWithProps from "components/shared/TextWithProps";
import Icon from "components/ui/Icon";
import Modal, { ModalApi } from "components/modal/Modal";
import { ApiNotification } from "utils/api/notifications";
import { ApiDashboardOverview } from "utils/api/dashboard";
import { ApiUserProfile } from "utils/api/user";
import * as Styled from "./styled";
import { NotificationUi } from "./types";

interface Props {
  notification: ApiNotification;
  ui: NotificationUi;
  dashboardOverview: ApiDashboardOverview;
  userProfile: ApiUserProfile;
  onClick?: () => void;
  onClickDismiss: (id: string) => void;
  isSuccess?: boolean;
  isDismissing: boolean;
}

const ListItem: React.FC<Props> = ({
  notification,
  ui,
  dashboardOverview,
  userProfile,
  onClick,
  onClickDismiss,
  isSuccess,
  isDismissing,
}) => {
  const modalRef = useRef<ModalApi>(null);

  const onClickLearnMore = () => {
    modalRef.current?.show();
  };

  const {
    title,
    message,
    image,
    icon,
    customIcon,
    actions,
    modalMessage,
    successMessage,
    isDismissable,
  } = ui;

  if (isSuccess) {
    return (
      <Styled.ListItem>
        <Styled.IconWrapper $color="leaf">
          <Icon name="party" color="violet" size="lg" />
        </Styled.IconWrapper>
        <Styled.Main>
          <UILabel.Large bold>
            {successMessage({ notification, userProfile, dashboardOverview }) ||
              "Success!"}
          </UILabel.Large>
        </Styled.Main>
      </Styled.ListItem>
    );
  }

  return (
    <>
      <Styled.ListItem>
        {image && <Styled.Image src={image} alt="Notification image" />}
        {(icon || customIcon) && (
          <Styled.IconWrapper>
            {icon ? (
              <Icon name={icon} color="violet" size="lg" />
            ) : (
              <img src={customIcon} alt="Notification icon" />
            )}
          </Styled.IconWrapper>
        )}

        <Styled.Main>
          <Styled.Content>
            <Styled.Text>
              <UILabel.Large bold>
                <TextWithProps
                  props={{ notification, userProfile, dashboardOverview }}
                >
                  {title}
                </TextWithProps>
              </UILabel.Large>
              <UILabel.Medium>
                <TextWithProps
                  props={{ notification, dashboardOverview, userProfile }}
                >
                  {message}
                </TextWithProps>
              </UILabel.Medium>
            </Styled.Text>

            {image && (
              <Styled.MobileImage>
                <img src={image} alt="Notification image" />
              </Styled.MobileImage>
            )}
          </Styled.Content>

          {actions && (
            <Styled.Actions>
              {actions({ onClick, onClickLearnMore }).map((action, index) => (
                <React.Fragment key={index}>{action}</React.Fragment>
              ))}
            </Styled.Actions>
          )}
        </Styled.Main>

        {actions ? (
          <Modal ref={modalRef}>
            <Styled.ModalBody>{modalMessage}</Styled.ModalBody>
          </Modal>
        ) : (
          <>
            <Styled.ArrowIcon>
              <Icon name="caretRightSolid" color="azure" />
            </Styled.ArrowIcon>
            <Styled.ListItemButton onClick={onClick} />
          </>
        )}

        {isDismissable && (
          <Styled.DismissButton
            onClick={() => onClickDismiss(notification.id)}
            disabled={isDismissing}
          >
            <Icon name="close" />
          </Styled.DismissButton>
        )}
      </Styled.ListItem>
    </>
  );
};

export default ListItem;
