import React, { useEffect, useState } from "react";
import BankLinking from "components/shared/ProductOnboarding/BankLinking";
import IdentityVerification from "components/shared/ProductOnboarding/IdentityVerification";
import TermsAcceptance from "components/shared/ProductOnboarding/TermsAcceptance";
import UnconfirmedMessage from "components/shared/ProductOnboarding/UnconfirmedMessage";
import { InfoType } from "components/shared/ProductOnboarding/types";
import {
  getTaxRefundAdvanceApplicationStatus,
  getUserProfile,
} from "store/api/apiSlice";
import { OnboardingTaskName } from "types/onboarding";
import { PartnerSlug, ProductName } from "types/general";
import TaxFiling from "./TaxFiling";
import TaxRefund from "./TaxRefund";

interface Props {
  showInfo: (infoType: InfoType) => () => void;
  partnerSlug?: PartnerSlug;
}

const Step: React.FC<Props> = ({ showInfo, partnerSlug }) => {
  const [isPolling, setIsPolling] = useState(false);
  const { data: taxRefundAdvanceApplicationStatus } =
    getTaxRefundAdvanceApplicationStatus({
      pollingInterval: isPolling ? 2000 : undefined,
    });
  const { data: userProfile } = getUserProfile();

  useEffect(() => {
    if (
      [
        OnboardingTaskName.bankLinking,
        OnboardingTaskName.taxFilingSubmission,
      ].includes(taxRefundAdvanceApplicationStatus?.currentTaskName)
    ) {
      setIsPolling(true);
    } else {
      setIsPolling(false);
    }
  }, [taxRefundAdvanceApplicationStatus?.currentTaskName]);

  if (!taxRefundAdvanceApplicationStatus) return null;

  if (taxRefundAdvanceApplicationStatus.applicationComplete) {
    return <TaxRefund />;
  }

  switch (taxRefundAdvanceApplicationStatus.currentTaskName) {
    case OnboardingTaskName.bankLinking:
      return (
        <BankLinking
          productName={ProductName.taxRefundAdvance}
          partnerSlug={partnerSlug}
          setIsPolling={setIsPolling}
        />
      );
    default:
  }

  // TODO: Do we need to confirm email?
  if (!userProfile.confirmed) return <UnconfirmedMessage />;

  switch (taxRefundAdvanceApplicationStatus.currentTaskName) {
    case OnboardingTaskName.termsAcceptance:
      return (
        <TermsAcceptance
          showInfo={showInfo}
          productName={ProductName.taxRefundAdvance}
        />
      );
    case OnboardingTaskName.identityVerification:
      return (
        <IdentityVerification
          showInfo={showInfo}
          productName={ProductName.taxRefundAdvance}
        />
      );
    case OnboardingTaskName.taxFilingSubmission:
      return <TaxFiling />;
    default:
      return <></>;
  }
};

export default Step;
