import React, { useEffect, useState } from "react";
import Loading from "components/ui/Loading";
import IntegrationErrorResolution from "components/shared/IntegrationErrorResolution";
import { OnboardingTaskName } from "types/onboarding";
import IdentityVerification from "components/shared/ProductOnboarding/IdentityVerification";
import UnconfirmedMessage from "components/shared/ProductOnboarding/UnconfirmedMessage";
import PendingMessage from "components/shared/ProductOnboarding/PendingMessage";
import CardIssuedMessage from "components/shared/ProductOnboarding/CardIssuedMessage";
import IneligibleMessage from "components/shared/ProductOnboarding/IneligibleMessage";
import EmploymentVerification from "components/shared/ProductOnboarding/EmploymentVerification";
import BankLinking from "components/shared/ProductOnboarding/BankLinking";
import { InfoType } from "components/shared/ProductOnboarding/types";
import {
  getCardApplicationStatus,
  getNotifications,
  getUserProfile,
} from "store/api/apiSlice";
import { getIntegrationErrors } from "utils/notifications";
import { POLLING_INTERVAL } from "utils/api/common";
import Repayment from "./Repayment";
import TermsWithIntro from "./TermsWithIntro";

interface Props {
  taskName: OnboardingTaskName;
  showInfo: (infoType: InfoType) => () => void;
}

const Step: React.FC<Props> = ({ taskName, showInfo }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const {
    data: userProfile,
    isLoading: isLoadingUserProfile,
    refetch: refetchUserProfile,
  } = getUserProfile();
  const {
    data: cardApplicationStatus,
    isLoading: isLoadingCardApplicationStatus,
  } = getCardApplicationStatus({
    pollingInterval: isPolling ? 2000 : undefined,
  });
  const { data: notifications, refetch: refetchNotifications } =
    getNotifications({
      pollingInterval: POLLING_INTERVAL,
    });
  const integrationErrors = getIntegrationErrors(notifications || []);

  const onSuccess = () => {
    setIsSuccess(true);
    setIsPolling(true);
    refetchUserProfile();
  };

  useEffect(() => {
    if (
      [
        OnboardingTaskName.bankLinking,
        OnboardingTaskName.payrollLinking,
      ].includes(cardApplicationStatus?.currentTaskName)
    ) {
      setIsPolling(true);
    } else {
      setIsPolling(false);
    }
  }, [cardApplicationStatus?.currentTaskName]);

  if (isLoadingUserProfile || isLoadingCardApplicationStatus)
    return <Loading />;

  if (cardApplicationStatus?.ineligible) return <IneligibleMessage />;

  switch (taskName) {
    case OnboardingTaskName.payrollLinking:
      return (
        <EmploymentVerification
          onboardingStatus={cardApplicationStatus}
          setIsPolling={setIsPolling}
        />
      );
    case OnboardingTaskName.bankLinking:
      return <BankLinking setIsPolling={setIsPolling} />;
    default:
  }

  if (integrationErrors.length) {
    return (
      <IntegrationErrorResolution
        refetch={refetchNotifications}
        integrationErrors={integrationErrors}
      />
    );
  }
  if (!userProfile.confirmed) return <UnconfirmedMessage />;
  if (!userProfile.eligibleForCard) return <PendingMessage />;
  if (isSuccess)
    return (
      <CardIssuedMessage
        isCardIssued={cardApplicationStatus?.cardApplicationComplete}
      />
    );

  switch (taskName) {
    case OnboardingTaskName.termsAcceptance:
      return <TermsWithIntro showInfo={showInfo} />;
    case OnboardingTaskName.identityVerification:
      return <IdentityVerification showInfo={showInfo} />;
    case OnboardingTaskName.repaymentSetup:
      return <Repayment onSuccess={onSuccess} />;
    default:
      return <></>;
  }
};

export default Step;
