import styled from "styled-components";
import Button, { BaseButton } from "components/ui/Button";
import { LinkButton } from "components/ui/Link";
import { Body } from "components/ui/Text";
import { colors, fontSizesUI, mediaMax, shadows } from "utils/theme";

export const List = styled.ul`
  display: grid;
  gap: 16px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: fit-content;

  & > li {
    display: block;
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 120px;
  margin-left: -40px;
  margin-right: -4px;
`;

export const IconWrapper = styled.div<{ $color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  flex: 0 0 auto;
  margin: 20px 0 20px 20px;
  align-self: flex-start;

  border-radius: 9999px;
  background: ${(props) =>
    colors[props.$color] ||
    props.$color ||
    `color-mix(in srgb, ${colors.heliotrope} 10%, ${colors.flax} 50%)`};

  img {
    width: 30px;
  }

  ${mediaMax("md")} {
    margin: 16px -4px 16px 16px;
    width: 40px;
    height: 40px;

    img,
    svg {
      height: 18px;
    }
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  flex: 1 1 auto;

  ${mediaMax("md")} {
    padding: 16px;
    gap: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 12px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${mediaMax("md")} {
    * {
      font-size: ${fontSizesUI.md}px;
    }
  }
`;

export const MobileImage = styled.div`
  position: relative;

  display: none;
  width: 96px;
  margin-right: -24px;
  flex: 0 0 auto;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateY(36px);

    width: 100%;
  }
`;

export const ActionButton = styled(Button)``;

export const ActionLinkButton = styled(LinkButton)``;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const ListItemButton = styled(BaseButton)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;

export const ArrowIcon = styled.div`
  margin-right: 16px;
  margin-left: -8px;
  order: 2;

  opacity: 0.5;

  pointer-events: none;
`;

export const ModalBody = styled(Body).attrs(() => ({ tag: "div" }))`
  text-align: left;
`;

export const DismissButton = styled(BaseButton)`
  position: absolute;
  top: 4px;
  right: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;

export const ListItem = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 0;

  border-radius: 12px;
  background: color-mix(in srgb, ${colors.azure} 10%, white);
  border: 1px solid ${colors.violet};
  box-shadow: ${shadows.tile};

  text-align: left;

  ${mediaMax("md")} {
    ${Image}, ${ArrowIcon} {
      display: none;
    }

    ${MobileImage} {
      display: block;
    }
  }
`;
