import { ApiIncomeTaxReturn } from "utils/api/incomeTaxReturns";
import { formatCurrency } from "utils/string";

export const formatAmount = (amount: number) =>
  formatCurrency(amount, true).split(".")[0];

export const getRefundAmountsFromIncomeTaxReturns = (
  incomeTaxReturns: ApiIncomeTaxReturn[]
) =>
  incomeTaxReturns.reduce(
    (acc, incomeTaxReturn) => {
      const key =
        incomeTaxReturn.jurisdiction === "Federal" ? "federal" : "state";

      return {
        ...acc,
        [key]: incomeTaxReturn.refundAmount,
      };
    },
    {
      federal: 0,
      state: 0,
    }
  );
