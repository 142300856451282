import React from "react";
import UILabel from "components/ui/UILabel";
import socureLogo from "assets/images/general/socure-logo.svg";
import Loading from "components/ui/Loading";
import * as Styled from "./styled";
import { ProgressStatus } from "./types";

interface Props {
  status: ProgressStatus;
  phoneNumber: string;
}

const WaitingForUpload: React.FC<Props> = ({ phoneNumber, status }) => (
  <Styled.Wrapper>
    <Styled.WarningMessage title="Important">
      <UILabel.Large>
        You must return to this screen after uploading your documents, or your
        verification will not be completed.
      </UILabel.Large>
    </Styled.WarningMessage>

    <Styled.Box>
      <Styled.Loading>
        <Loading />
        <UILabel.Large>
          {status === "WAITING_FOR_REDIRECT_METHOD"
            ? `Link sent to ${phoneNumber}`
            : "Waiting for upload"}
        </UILabel.Large>
      </Styled.Loading>
    </Styled.Box>

    <Styled.SocureLogo>
      <UILabel.Small>powered by</UILabel.Small>
      <img src={socureLogo} alt="Socure" />
    </Styled.SocureLogo>
  </Styled.Wrapper>
);

export default WaitingForUpload;
