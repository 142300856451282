import React from "react";
import { Body } from "components/ui/Text";
import LoadingDots from "components/ui/LoadingDots";
import FormContentLayout from "components/layout/FormContentLayout";

const VerificationMessage: React.FC = () => (
  <FormContentLayout heading="Please wait.">
    <Body>Your account is being set up.</Body>

    <LoadingDots />
  </FormContentLayout>
);

export default VerificationMessage;
