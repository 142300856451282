import React from "react";
import Button, { ButtonLevel } from "components/ui/Button";
import Icon from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import FormContentLayout from "components/layout/FormContentLayout";
import TextWithProps from "components/shared/TextWithProps";
import { PartnerSlug, ProductName } from "types/general";
import * as Styled from "./styled";
import { DEFAULT_INTRO, INTRO } from "./constants";

interface Props {
  hideIntro: () => void;
  productName?: ProductName;
  partnerSlug?: PartnerSlug;
}

const Intro: React.FC<Props> = ({
  hideIntro,
  productName = ProductName.taxRefundAdvance,
  partnerSlug,
}) => {
  const { items, heading, subheading } = INTRO[productName] || DEFAULT_INTRO;

  return (
    <FormContentLayout
      heading={<TextWithProps>{heading}</TextWithProps>}
      subheading={<TextWithProps>{subheading}</TextWithProps>}
      actions={
        <Button
          onClick={hideIntro}
          level={ButtonLevel.cta}
          data-test="btn-continue"
        >
          Continue
        </Button>
      }
    >
      <Styled.List>
        {items.map((item) => (
          <Styled.ListItem key={item.icon} $isComplete={item.isComplete}>
            <Styled.IconWrapper>
              <Icon
                size="md"
                name={item.isComplete ? "checkmark" : item.icon}
              />
            </Styled.IconWrapper>

            <Styled.Text>
              <UILabel.XLarge bold>
                <TextWithProps props={{ partnerSlug }}>
                  {item.title}
                </TextWithProps>
              </UILabel.XLarge>
              <UILabel.Large color="slate">
                <TextWithProps props={{ partnerSlug }}>
                  {item.description}
                </TextWithProps>
              </UILabel.Large>
            </Styled.Text>
          </Styled.ListItem>
        ))}
      </Styled.List>
    </FormContentLayout>
  );
};

export default Intro;
