import React from "react";
import { IconName } from "components/ui/Icon";
import { ProductName } from "types/general";
import { OnboardingTask, OnboardingTaskName } from "types/onboarding";
import { FormStepStatus } from "types/task";
import { getResetConstant } from "utils/reset";
import TaxEligibilityFooter from "./TaxEligibilityFooter";

// This is exhaustive, although some titles aren't currently used in the UI
export const DEFAULT_TASK_CONTENT_MAP: {
  [name in OnboardingTaskName]: {
    title: string;
  };
} = {
  [OnboardingTaskName.signup]: {
    title: "Sign up",
  },
  [OnboardingTaskName.bankLinking]: {
    title: "Link checking account",
  },
  [OnboardingTaskName.payrollLinking]: {
    title: "Verify income",
  },
  [OnboardingTaskName.termsAcceptance]: {
    title: "Review terms",
  },
  [OnboardingTaskName.identityVerification]: {
    title: "Submit application",
  },
  [OnboardingTaskName.repaymentSetup]: {
    title: "Set up autopay",
  },
  [OnboardingTaskName.taxFilingSubmission]: {
    title: "File taxes",
  },
};

const TAX_REFUND_ADVANCE_TASK_CONTENT_MAP = {
  ...DEFAULT_TASK_CONTENT_MAP,
  [OnboardingTaskName.identityVerification]: {
    title: "Verify identity",
  },
};

export const TASK_CONTENT_MAP = {
  [ProductName.earnedWageAccess]: DEFAULT_TASK_CONTENT_MAP,
  [ProductName.taxRefundAdvance]: TAX_REFUND_ADVANCE_TASK_CONTENT_MAP,
};

const DEFAULT_TASKS = [
  {
    name: OnboardingTaskName.signup,
    state: FormStepStatus.ready,
  },
  {
    name: OnboardingTaskName.bankLinking,
    state: FormStepStatus.incomplete,
  },
  {
    name: OnboardingTaskName.payrollLinking,
    state: FormStepStatus.incomplete,
  },
  {
    name: OnboardingTaskName.identityVerification,
    state: FormStepStatus.incomplete,
  },
];

const TAX_REFUND_ADVANCE_TASKS = [
  {
    name: OnboardingTaskName.signup,
    state: FormStepStatus.ready,
  },
  {
    name: OnboardingTaskName.bankLinking,
    state: FormStepStatus.incomplete,
  },
  {
    name: OnboardingTaskName.identityVerification,
    state: FormStepStatus.incomplete,
  },
  {
    name: OnboardingTaskName.taxFilingSubmission,
    state: FormStepStatus.incomplete,
  },
];

interface ProductConfig {
  title: string;
  icon: IconName;
  defaultTasks: OnboardingTask[];
  hideDisclosure?: boolean;
  eligibilityFooter?: React.ReactNode;
}

export const DEFAULT_PRODUCT_CONFIG: ProductConfig = {
  title: "Card Application",
  icon: "creditCard",
  defaultTasks: DEFAULT_TASKS,
  eligibilityFooter: getResetConstant("eligibility") as string,
};

const TAX_REFUND_ADVANCE_CONFIG: ProductConfig = {
  title: "Tax Filing",
  icon: "calculator",
  defaultTasks: TAX_REFUND_ADVANCE_TASKS,
  eligibilityFooter: <TaxEligibilityFooter />,
  hideDisclosure: true,
};

export const PRODUCT_CONFIG = {
  [ProductName.earnedWageAccess]: DEFAULT_PRODUCT_CONFIG,
  [ProductName.taxRefundAdvance]: TAX_REFUND_ADVANCE_CONFIG,
};

export const PRODUCT_NAME_TO_FLOW_MAP = {
  [ProductName.earnedWageAccess]: "ewa",
  [ProductName.taxRefundAdvance]: "tax",
};
