import React, { useState } from "react";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";

export interface DrawerApi {
  openWith: (sidebar: React.ReactNode) => void;
  close: () => void;
}

interface Props {
  children: (props: DrawerApi) => React.ReactNode;
}

const DrawerLayout: React.FC<Props> = ({ children }) => {
  const [sidebar, setSidebar] = useState<React.ReactNode | undefined>();

  const openWith = (s: React.ReactNode) => {
    setSidebar(s);
  };

  const close = () => setSidebar(undefined);

  const isOpen = !!sidebar;

  return (
    <Styled.Wrapper>
      <Styled.Main>{children({ openWith, close })}</Styled.Main>
      <Styled.Sidebar $isOpen={isOpen}>
        <Styled.SidebarInner>
          <Styled.CloseButton onClick={close} aria-label="Close sidebar">
            <Icon name="close" />
          </Styled.CloseButton>

          {sidebar}
        </Styled.SidebarInner>
      </Styled.Sidebar>
    </Styled.Wrapper>
  );
};

export default DrawerLayout;
