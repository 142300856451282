import React from "react";
import styled from "styled-components";
import Tile from "components/ui/Tile";
import { mediaMax } from "utils/theme";

const StyledTile = styled(Tile)`
  display: grid;
  gap: 24px;

  & > * {
    margin: 0 !important;
  }

  ${mediaMax("lg")} {
    gap: 16px;
  }

  ${mediaMax("md")} {
    padding: 24px;

    border-radius: 16px;
  }
`;

interface Props {
  children: React.ReactNode;
}

const FormSection: React.FC<Props> = ({ children, ...props }) => (
  <StyledTile size="xl" {...props}>
    {children}
  </StyledTile>
);

export default FormSection;
