import styled, { css } from "styled-components";
import { colors, fontSizesUI } from "utils/theme";

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;
  flex: 0 0 auto;

  border-radius: 9999px;
  background: ${colors.flax};
  color: ${colors.violet};
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ListItem = styled.li<{ $isComplete?: boolean }>`
  position: relative;

  display: flex;
  gap: 16px;

  &:not(:last-child) {
    &::after {
      content: "";

      position: absolute;
      left: 24px;
      top: 24px;
      z-index: -1;
      transform: translateX(-50%);

      width: 4px;
      height: 100%;

      background: ${colors.flax};
    }
  }

  ${({ $isComplete }) =>
    $isComplete &&
    css`
      &::after,
      ${IconWrapper} {
        background: ${colors.azure};
        color: white;
      }
    `};
`;

export const List = styled.ol`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  list-style-type: none;
`;

export const ItemTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ItemTag = styled.div`
  display: flex;
  padding: 3px 8px;
  min-height: 20px;
  align-items: center;

  background: ${colors.leaf};
  border-radius: 4px;

  line-height: 1;
  font-size: ${fontSizesUI.sm}px;
`;
