import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Eligibility } from "types/signup";
import FormContentLayout from "components/layout/FormContentLayout";
import { SubmitButton } from "components/form";
import { ButtonLevel } from "components/ui/Button";
import Fields from "./Fields";

const DEFAULT_ELIGIBILITY = {
  payroll: Eligibility.indeterminate,
};

interface Props {}

const Form: React.FC<Props> = () => {
  const [eligibility, setEligibility] = useState<{
    payroll: Eligibility;
  }>(DEFAULT_ELIGIBILITY);
  const [hasError, setHasError] = useState({
    payroll: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const form = useFormContext();

  const updateEligibility = (key: "payroll") => (value: Eligibility) =>
    setEligibility((s) => ({ ...s, [key]: value }));

  const updateHasError = (key: "payroll") => (value: boolean) =>
    setHasError((s) => ({ ...s, [key]: value }));

  const updateValidation = (accountEligibility) => {
    setHasError(
      Object.entries(accountEligibility).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value === Eligibility.indeterminate,
        }),
        { bank: false, payroll: false }
      )
    );
  };

  const scrollToErrorByName = (name) => {
    const errorEl = document.querySelector(`[name="${name}"]`);
    const rect = errorEl?.getBoundingClientRect();
    if (rect?.top) {
      window.scrollTo({
        top: window.scrollY + rect.top - 108,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    form.setValue("eligibility", eligibility);
  }, [eligibility]);

  useEffect(() => {
    setIsSubmitted(form.formState.isSubmitted);
  }, [form.formState.isSubmitted, form.formState.submitCount]);

  useEffect(() => {
    setHasError({ payroll: false });
    if (isSubmitted) {
      updateValidation(eligibility);
    }
  }, [eligibility, isSubmitted, form.formState.submitCount]);

  useEffect(() => {
    if (hasError.payroll) {
      scrollToErrorByName("payrollName");
    }
  }, [hasError]);

  return (
    <FormContentLayout
      heading="Tell us about your primary source of income."
      actions={<SubmitButton level={ButtonLevel.cta}>Continue</SubmitButton>}
    >
      <Fields
        isSubmitting={form.formState.isSubmitting}
        hasError={hasError}
        updateEligibility={updateEligibility}
        updateHasError={updateHasError}
        eligibility={eligibility}
      />
    </FormContentLayout>
  );
};

export default Form;
