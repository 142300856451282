import { PartnerSlug } from "types/general";

export const getHelpMessage = (partnerSlug?: PartnerSlug) => {
  if (partnerSlug === PartnerSlug.msufcu) {
    return "In order to get free tax filing and access your refund early if you are eligible, you must elect to deposit your refund into the account below. You'll be asked to confirm this again before submitting your return.";
  }

  if (Object.values(PartnerSlug).includes(partnerSlug)) {
    return "In order to get a credit for tax filing and access your refund early if you are eligible, you must elect to deposit your refund into the account below. You'll be asked to confirm this again before submitting your return.";
  }

  return "In order to access your refund early if you are eligible, you must elect to deposit your refund into the account below. You'll be asked to confirm this again before submitting your return.";
};
