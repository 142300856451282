import { FormStepStatus } from "./task";

export enum OnboardingTaskName {
  signup = "signup",
  payrollLinking = "payroll_linking",
  bankLinking = "bank_linking",
  termsAcceptance = "terms_acceptance",
  identityVerification = "identity_verification",
  repaymentSetup = "repayment_setup",
  taxFilingSubmission = "tax_filing_submission",
}

export interface OnboardingTask {
  name: OnboardingTaskName;
  state: FormStepStatus;
}

export enum OnboardingState {
  unregistered = "unregistered",
  confirmedEmail = "confirmed_email",
  registered = "registered",
  linkedCoreFinancialInstitutionAccount = "linked_core_financial_institution_account",
  linkedIncomeAccount = "linked_income_account",
  skippedIncomeAccountLinking = "skipped_income_account_linking",
  incomeAccountLinkingUnsupported = "income_account_linking_unsupported",
  onboarded = "onboarded",
  acceptedTermsAndConditions = "accepted_terms_and_conditions",
  submittedIdentityVerification = "submitted_identity_verification",
  verifiedIdentity = "verified_identity",
  switchedDirectDeposit = "switched_direct_deposit",
  acknowledgedRepaymentAccount = "acknowledged_repayment_account",
  issuedCard = "issued_card",
  activatedCard = "activated_card",
  usedCard = "used_card",
  deactivated = "deactivated",
}
