import React, { useRef } from "react";
import { ModalApi } from "components/modal/Modal";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import AdminExportDataModal from "components/modal/AdminExportDataModal";

const ExportDataButton = () => {
  const modalRef = useRef<ModalApi>(null);

  return (
    <>
      <Button
        size={ButtonSize.md}
        level={ButtonLevel.tertiary}
        fullWidth
        onClick={() => modalRef.current?.show()}
      >
        Export Data
      </Button>

      <AdminExportDataModal ref={modalRef} />
    </>
  );
};

export default ExportDataButton;
