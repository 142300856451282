import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import Button from "components/ui/Button";
import {
  FormMessage,
  FormSection,
  PasswordField,
  PasswordStrength,
  SubmitButton,
} from "components/form";
import FormContentLayout from "components/layout/FormContentLayout";
import PasswordStrengthContainer from "./PasswordStrengthContainer";
import { CurrentUser } from "./types";

interface Props {
  currentUser: CurrentUser;
  showError: () => void;
  hasPasswordStrengthError: boolean;
  apiErrorMessages: string[];
  isSignup?: boolean;
}

const Fields: React.FC<Props> = ({
  currentUser,
  showError,
  hasPasswordStrengthError,
  apiErrorMessages,
  isSignup,
}) => {
  const { watch } = useFormContext();
  const form = useFormState();
  const password = watch("user[password]") || "";

  return (
    <PasswordStrengthContainer
      password={password}
      blacklist={[currentUser?.first_name, currentUser?.last_name]}
    >
      {({ strength }) => (
        <FormContentLayout
          heading={<>{isSignup ? "Create a" : "Edit your"} password.</>}
          subheading={
            <>
              Set a <strong>strong</strong> password to keep your account safe.
            </>
          }
          actions={
            strength.score === 4 ? (
              <SubmitButton
                type="submit"
                data-test="btn-submit"
                disabled={form.isSubmitting}
              >
                {form.isSubmitting ? "Submitting..." : "Set Password"}
              </SubmitButton>
            ) : (
              <Button type="button" onClick={showError}>
                Set Password
              </Button>
            )
          }
        >
          <FormSection>
            {hasPasswordStrengthError && strength.score < 4 && (
              <FormMessage>
                <div>
                  You must choose a <strong>strong</strong> password. Please see
                  requirements below.
                </div>
              </FormMessage>
            )}

            {apiErrorMessages?.length > 0 && (
              <FormMessage>
                <div>
                  {apiErrorMessages.map((message) => (
                    <div key={message}>{message}</div>
                  ))}
                </div>
              </FormMessage>
            )}

            <PasswordField
              label="Password"
              name="user[password]"
              placeholder="Password"
              autoComplete="new-password"
              required
            />

            <PasswordStrength {...strength} password={password} />
          </FormSection>
        </FormContentLayout>
      )}
    </PasswordStrengthContainer>
  );
};

export default Fields;
