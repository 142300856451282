import React from "react";
import DepositSwitching from "components/shared/ProductOnboarding/DepositSwitching";
import ConfirmRepaymentAccount from "components/shared/ProductOnboarding/ConfirmRepaymentAccount";
import { RepaymentMethod } from "utils/api/user";
import { getUserProfile } from "store/api/apiSlice";

interface Props {
  onSuccess: () => void;
}

const Repayment: React.FC<Props> = ({ onSuccess }) => {
  const { data: userProfile, isLoading } = getUserProfile();

  if (isLoading) return null;

  switch (userProfile?.repaymentMethod) {
    case RepaymentMethod.directDepositFlow:
      return <DepositSwitching onSuccess={onSuccess} />;
    case RepaymentMethod.achDebitFlow:
      return <ConfirmRepaymentAccount onSuccess={onSuccess} />;
    default:
      return <></>;
  }
};

export default Repayment;
