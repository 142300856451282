import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  getAdminUsers,
  getAdminUsersApplicants,
  getAdminUsersCardholders,
  getAdminUsersEligible,
} from "store/admin/api/apiSlice";
import Loading from "components/ui/Loading";
import Pagination from "components/ui/Pagination";
import UILabel from "components/ui/UILabel";
import { getUserProfile } from "store/api/apiSlice";
import * as Styled from "./styled";
import Table from "../../components/Table";
import {
  getColumnValue,
  isOtherAdmin,
} from "../../components/users-table-utils";

const getQueryString = (params) =>
  Object.entries(params)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const COLUMN_HEADING_MAP = {
  id: "ID",
  name: "Name",
  email: "Email",
  admin: "Admin",
  onboardingStatus: "Last step",
  deactivated: "Account status",
  lastSignInAt: "Last login (PDT)",
  createdAt: "Created at (PDT)",
  dsl: "DSL",
  latestTransaction: "Last transaction",
  partner: "Partner",
  referral: "Referrer",
};

const COLUMNS = [
  "id",
  "name",
  "email",
  "admin",
  "onboardingStatus",
  "partner",
  "referral",
  "deactivated",
  "lastSignInAt",
  "createdAt",
];

const CARDHOLDER_COLUMNS = [
  "id",
  "name",
  "email",
  "onboardingStatus",
  "partner",
  "referral",
  "deactivated",
  "lastSignInAt",
  "createdAt",
  "dsl",
  "latestTransaction",
];

const FILTERS = [
  { label: "All", group: null, sortBy: null },
  { label: "Cardholders", group: "cardholders", sortBy: null },
  {
    label: "Active Cardholders",
    group: "cardholders",
    sortBy: "latest_transaction",
  },
  { label: "Applicants", group: "applicants", sortBy: null },
  { label: "Eligible", group: "eligible", sortBy: null },
];

const Users = () => {
  const [params] = useSearchParams();
  const page = parseInt(params.get("page"), 10) || 1;
  const userGroup = params.get("user_group");
  const sortBy = params.get("sort_by");
  const requestParams = { page, sortBy };
  const { data: usersData, isLoading: isLoadingAll } = getAdminUsers(
    requestParams,
    {
      skip: !!userGroup,
    }
  );
  const { data: cardholdersData, isLoading: isLoadingCardholders } =
    getAdminUsersCardholders(requestParams, {
      skip: userGroup !== "cardholders",
    });
  const { data: applicantsData, isLoading: isLoadingApplicants } =
    getAdminUsersApplicants(requestParams, {
      skip: userGroup !== "applicants",
    });
  const { data: eligibleData, isLoading: isLoadingEligible } =
    getAdminUsersEligible(requestParams, { skip: userGroup !== "eligible" });
  const { data: userProfile } = getUserProfile();

  if (
    isLoadingAll ||
    isLoadingCardholders ||
    isLoadingApplicants ||
    isLoadingEligible
  )
    <Loading />;

  const data = (() => {
    switch (userGroup) {
      case "cardholders":
        return cardholdersData;
      case "applicants":
        return applicantsData;
      case "eligible":
        return eligibleData;
      default:
        return usersData;
    }
  })();
  const { users, totalPages } = data || {};

  const columns = ["cardholders", "cardholdersByLatestTransaction"].includes(
    userGroup
  )
    ? CARDHOLDER_COLUMNS
    : COLUMNS;

  if (!users) return null;

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.HeaderSection>
          <UILabel.Medium>Show:</UILabel.Medium>
          {FILTERS.map((filter) => (
            <Styled.HeaderFilter
              key={`${filter.group}${filter.sortBy}`}
              to={`?${getQueryString({
                user_group: filter.group,
                sort_by: filter.sortBy,
              })}`}
              $isActive={userGroup === filter.group && sortBy === filter.sortBy}
            >
              {filter.label}
            </Styled.HeaderFilter>
          ))}
        </Styled.HeaderSection>
      </Styled.Header>

      <Table>
        <thead>
          <tr>
            {columns.map((name) => (
              <th key={name}>{COLUMN_HEADING_MAP[name]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              {columns.map((name, index) => (
                <td key={index} data-heading={COLUMN_HEADING_MAP[name]}>
                  {getColumnValue({
                    user,
                    isOtherAdmin: isOtherAdmin(user, userProfile),
                    name,
                  })}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {totalPages > 1 && <Pagination current={page} total={totalPages} />}
    </Styled.Wrapper>
  );
};

export default Users;
