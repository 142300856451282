import React, { useRef } from "react";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import PlaidModal, {
  ModalApi as PlaidModalApi,
} from "components/shared/PlaidModal";
import {
  ApiNotification,
  ApiNotificationErrorType,
  ApiNotificationSource,
  ApiNotificationType,
} from "utils/api/notifications";
import {
  getDashboardOverview,
  getUserProfile,
  usePostNotificationMarkAsReadMutation,
} from "store/api/apiSlice";
import { ProductName } from "types/general";
import ListItem from "./ListItem";
import * as Styled from "./styled";
import { getNotificationUi } from "./utils";

interface Props {
  notifications?: ApiNotification[];
  onResolveIntegrationError?: () => void;
  refetchNotifications?: () => void;
}

const NotificationsList: React.FC<Props> = ({
  notifications,
  onResolveIntegrationError,
  refetchNotifications,
}) => {
  const { data: dashboardOverview } = getDashboardOverview();
  const { data: userProfile } = getUserProfile();
  const [markAsRead, postMarkAsReadContext] =
    usePostNotificationMarkAsReadMutation();
  const pinwheelModalRef = useRef<PinwheelModalApi>(null);
  const plaidModalRef = useRef<PlaidModalApi>(null);

  const pinwheelRelinkHandler = (id) => {
    if (pinwheelModalRef && pinwheelModalRef.current) {
      pinwheelModalRef.current.open(id);
    }
  };

  const plaidRelinkHandler = (id) => {
    if (plaidModalRef && plaidModalRef.current) {
      plaidModalRef.current.open(id);
    }
  };

  const onboardingStateHandler = (product: ProductName) => {
    let path = "";
    switch (product) {
      case ProductName.earnedWageAccess:
        path = "/apply";
        break;
      case ProductName.taxRefundAdvance:
        path = "/tax-filing";
        break;
      default:
    }

    window.location.href = path;
  };

  const mapNotificationToHandler = async (notification: ApiNotification) => {
    if (
      notification.params?.errorType ===
      ApiNotificationErrorType.monitoring_status_unavailable
    ) {
      switch (notification.params?.source) {
        case ApiNotificationSource.pinwheel:
          pinwheelRelinkHandler(notification.id);
          break;
        case ApiNotificationSource.plaid:
          plaidRelinkHandler(notification.id);
          break;
        default:
          break;
      }
    } else {
      switch (notification.type) {
        case ApiNotificationType.optedToSkipPayrollLinking:
          pinwheelRelinkHandler(notification.id);
          break;
        case ApiNotificationType.onboardingState:
          onboardingStateHandler(notification.params?.product);
          break;
        default:
          break;
      }
    }
  };

  const onRelinkSuccess = () => {
    if (onResolveIntegrationError) {
      onResolveIntegrationError();
    }
  };

  const onClickDismiss = async (id) => {
    await markAsRead(id);

    refetchNotifications();
  };

  const notificationsWithUi = notifications
    .map((notification) => ({
      notification,
      ui: getNotificationUi({ notification, userProfile }),
    }))
    .filter((notification) => !!notification.ui);

  if (notificationsWithUi.length === 0) return null;

  return (
    <>
      <Styled.List>
        {notificationsWithUi.map(({ notification, ui }) => (
          <li key={notification.id}>
            <ListItem
              notification={notification}
              ui={ui}
              dashboardOverview={dashboardOverview}
              userProfile={userProfile}
              onClick={() => {
                mapNotificationToHandler(notification);
              }}
              onClickDismiss={onClickDismiss}
              isDismissing={postMarkAsReadContext?.isLoading}
            />
          </li>
        ))}
      </Styled.List>

      <PinwheelModal ref={pinwheelModalRef} onSuccess={onRelinkSuccess} />

      <PlaidModal ref={plaidModalRef} onSuccess={onRelinkSuccess} />
    </>
  );
};

export default NotificationsList;
