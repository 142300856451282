import React from "react";
import Icon, { IconName } from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import * as Styled from "./styled";

export interface IconListItem {
  icon: IconName;
  title: string;
  body: React.ReactNode;
}

interface Props {
  items: IconListItem[];
  isLarge?: boolean;
}

const IconList: React.FC<Props> = ({ items, isLarge }) => {
  const Title = isLarge ? UILabel.XLarge : UILabel.Large;
  const Body = isLarge ? UILabel.Large : UILabel.Medium;

  return (
    <Styled.List $isLarge={isLarge}>
      {items.map((item) => (
        <Styled.ListItem key={item.icon}>
          <Styled.IconWrapper>
            <Icon
              name={item.icon}
              color="violet"
              size={isLarge ? "md" : "sm"}
            />
          </Styled.IconWrapper>

          <Styled.ItemText>
            <Title bold>{item.title}</Title>
            <Body color="slate">{item.body}</Body>
          </Styled.ItemText>
        </Styled.ListItem>
      ))}
    </Styled.List>
  );
};

export default IconList;
