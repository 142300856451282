import React from "react";
import styled, { css } from "styled-components";
import { colors } from "utils/theme";
import DollarCircle from "./Icons/DollarCircle";
import PiggyBank from "./Icons/PiggyBank";
import CreditCard from "./Icons/CreditCard";
import Check from "./Icons/Check";

const DEFAULT = {
  outline: colors.violet,
  primary: `color-mix(in srgb, ${colors.azure} 33%, white)`,
  accent: `color-mix(in srgb, ${colors.heliotrope} 33%, white)`,
};

interface StyleProps {
  $outline?: string;
  $primary?: string;
  $accent?: string;
  $reverse?: boolean;
  $size: number;
}

const getPrimary = (props: StyleProps) => {
  if (props.$primary) return props.$primary;
  if (props.$reverse) return DEFAULT.accent;
  return DEFAULT.primary;
};

const getAccent = (props: StyleProps) => {
  if (props.$accent) return props.$accent;
  if (props.$reverse) return DEFAULT.primary;
  return DEFAULT.accent;
};

const StyledIcon = styled.div<StyleProps>`
  ${(props) => css`
    height: ${props.$size}px;
    --color-outline: ${props.$outline || DEFAULT.outline};
    --color-primary: ${getPrimary(props)};
    --color-accent: ${getAccent(props)};
  `}
`;

const getIcon = (name: string) => {
  switch (name) {
    case "piggyBank":
      return PiggyBank;
    case "creditCard":
      return CreditCard;
    case "check":
      return Check;
    case "dollarCircle":
    default:
      return DollarCircle;
  }
};

interface Props {
  outline?: string;
  primary?: string;
  accent?: string;
  reverse?: boolean;
  name: string;
  size?: number;
}

const ColorIcon: React.FC<Props> = ({
  name,
  outline,
  primary,
  accent,
  reverse,
  size = 24,
}) => {
  const Icon = getIcon(name);

  return (
    <StyledIcon
      as={Icon}
      $outline={outline}
      $primary={primary}
      $accent={accent}
      $reverse={reverse}
      $size={size}
    />
  );
};

export default ColorIcon;
