import React from "react";
import App from "App";
import Flow from "./Flow";

const TaxRefundAdvanceApplicationView: React.FC = () => (
  <App withAuth>
    <Flow />
  </App>
);

export default TaxRefundAdvanceApplicationView;
