import React from "react";
import {
  InputField,
  CheckboxField,
  FormSection,
  HelpMessage,
} from "components/form";
import { openWindow } from "utils/window";
import UILabel from "components/ui/UILabel";

interface Props {}

const Fields: React.FC<Props> = () => {
  const onClickViewTerms = (e) => {
    e.preventDefault();
    openWindow(e.target.href);
  };

  return (
    <FormSection>
      <HelpMessage>
        Please enter your legal name for ID verification.
      </HelpMessage>

      <InputField
        name="user.firstName"
        label="First name"
        placeholder="Val"
        required
      />
      <InputField
        label="Last name"
        name="user.lastName"
        placeholder="Clark"
        required
      />
      <InputField
        name="user.email"
        errorName="email"
        label="Email"
        required
        placeholder="val.clark@example.com"
        autoComplete="email"
        type="email"
        maxLength={255}
      />

      <CheckboxField
        required
        name="checked"
        value="yes"
        label={
          <UILabel.Medium>
            By checking this box, you agree to our{" "}
            <a onClick={onClickViewTerms} href="/e-sign-consent-agreement">
              E-Sign Consent Agreement
            </a>
            ,{" "}
            <a onClick={onClickViewTerms} href="/terms-of-service">
              Terms of Service
            </a>
            , and{" "}
            <a onClick={onClickViewTerms} href="/privacy-policy">
              Privacy Policy
            </a>
            .
          </UILabel.Medium>
        }
      />
    </FormSection>
  );
};

export default Fields;
