import { PartnerSlug, ProductName } from "types/general";
import { ApiNotification } from "utils/api/notifications";
import {
  ACCOUNT_SETUP_STATES,
  CARDHOLDER_STATES,
} from "utils/constants/onboarding";
import { ApiUserProfile } from "utils/api/user";
import { formatCurrency } from "utils/string";
import calculatorIcon from "assets/images/icon/calculator.svg";
import creditCardIcon from "assets/images/icon/credit-card.svg";
import { NotificationMessageProps, NotificationUi } from "../types";

const getEarnedWageAccessOnboardingTitle = ({
  notification,
}: NotificationMessageProps) => {
  const { state } = notification.params;
  if (ACCOUNT_SETUP_STATES.includes(state)) {
    return "Finish setting up your account";
  }

  return "Continue your Reset card application";
};

const getEarnedWageAccessOnboardingMessage = ({
  notification,
  dashboardOverview,
}: NotificationMessageProps) => {
  const { state } = notification.params;
  if (ACCOUNT_SETUP_STATES.includes(state)) {
    return "Get daily access to your income as you earn it!";
  }

  const { currentAmount, maxAmount } = dashboardOverview?.dsl || {};

  if (maxAmount > currentAmount || currentAmount === 0) {
    return "Get daily access to your income as you earn it!";
  }

  return `You're almost there! Access your ${formatCurrency(currentAmount, true)} spending limit in minutes.`;
};

const EARNED_WAGE_ACCESS_ONBOARDING_NOTIFICATION: NotificationUi = {
  title: getEarnedWageAccessOnboardingTitle,
  message: getEarnedWageAccessOnboardingMessage,
  customIcon: creditCardIcon,
};

const getEarnedWageAccessOnboardingNotification = ({
  notification,
  userProfile,
}: {
  notification: ApiNotification;
  userProfile: ApiUserProfile;
}) => {
  if (!notification.requireAction) return undefined;

  if (
    !ACCOUNT_SETUP_STATES.includes(notification.params.state) &&
    !userProfile?.eligibleForCard
  )
    return undefined;

  return EARNED_WAGE_ACCESS_ONBOARDING_NOTIFICATION;
};

const getTaxRefundAdvanceOnboardingMessage = ({
  userProfile,
}: {
  userProfile: ApiUserProfile;
}) => {
  if (userProfile.partnerSlug === PartnerSlug.msufcu) {
    return "See if you qualify for free filing and an advance on your tax refund!";
  }

  if (userProfile.partnerSlug) {
    return "See if you qualify for a credit for filing your taxes and an advance on your tax refund!";
  }

  return "See if you qualify for an advance on your tax refund!";
};

const TAX_REFUND_ADVANCE_ONBOARDING_NOTIFICATION: NotificationUi = {
  title: "Finish filing your taxes",
  message: getTaxRefundAdvanceOnboardingMessage,
  customIcon: calculatorIcon,
};

export const getOnboardingStateNotificationUi = ({
  notification,
  userProfile,
}: {
  notification: ApiNotification;
  userProfile: ApiUserProfile;
}) => {
  if (CARDHOLDER_STATES.includes(notification.params.state)) return undefined;

  switch (notification.params?.product) {
    case ProductName.earnedWageAccess:
      return getEarnedWageAccessOnboardingNotification({
        notification,
        userProfile,
      });
    case ProductName.taxRefundAdvance:
      return TAX_REFUND_ADVANCE_ONBOARDING_NOTIFICATION;
    default:
      return undefined;
  }
};
