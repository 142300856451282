import React, { useEffect, useState } from "react";
import Loading from "components/ui/Loading";
import { InfoType } from "components/shared/ProductOnboarding/types";
import DslExplanation from "components/shared/ProductOnboarding/DslExplanation";
import TermsAcceptance from "components/shared/ProductOnboarding/TermsAcceptance";
import { getDashboardOverview, getUserProfile } from "store/api/apiSlice";
import { isDefined } from "utils/object";

interface Props {
  showInfo: (infoType: InfoType) => () => void;
}

const TermsWithIntro: React.FC<Props> = ({ showInfo }) => {
  const params = new URLSearchParams(window.location.search);
  const skipIntro = JSON.parse(params.get("skip_intro")) || false;
  const [showDslExplanation, setShowDslExplanation] = useState(!skipIntro);
  const { data: userProfile, isLoading: isUserProfileLoading } =
    getUserProfile();
  const { data: dashboard, isLoading: isDashboardLoading } =
    getDashboardOverview();

  useEffect(() => {
    const maxAmount = dashboard?.dsl?.maxAmount;
    if (isDefined(maxAmount) && maxAmount === 0) {
      setShowDslExplanation(false);
    }
  }, [dashboard?.dsl?.maxAmount]);

  useEffect(() => {
    if (
      isDefined(userProfile?.enableProbationaryLimit) &&
      !userProfile?.enableProbationaryLimit
    ) {
      setShowDslExplanation(false);
    }
  }, [userProfile?.enableProbationaryLimit]);

  if (isDashboardLoading || isUserProfileLoading) return <Loading />;

  if (showDslExplanation)
    return (
      <DslExplanation
        setShowDslExplanation={setShowDslExplanation}
        dsl={dashboard?.dsl}
      />
    );

  return <TermsAcceptance showInfo={showInfo} />;
};

export default TermsWithIntro;
