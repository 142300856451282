import React from "react";

const TaxEligibilityFooter = () => (
  <>
    <p>
      Tax e-file services provided by April Tax Solutions Inc. (“april”) and may
      be subject to filing fees. Reset and Stearns Bank, N.A., do not provide
      tax advice, preparation, or related services and are not responsible for
      the accuracy or outcome of services provided by april. For more
      information, please see april's{" "}
      <a href="https://www.getapril.com/terms-of-use" target="_blank">
        Terms of Use
      </a>{" "}
      on their website.
    </p>
    <p>
      Reset is a financial technology company, not a bank. Banking services are
      provided by Stearns Bank, Member FDIC. The Reset card is issued by Stearns
      Bank pursuant to a license from Visa U.S.A. Inc. and may be used in the
      U.S. (excluding territories) where Visa cards are accepted.
    </p>
    <p>
      You are not required to obtain or use the Reset card to file your tax
      return through april or receive any tax refunds to which you are entitled,
      but you must have a Reset card to be eligible to receive a tax refund
      advance.
    </p>
    <p>
      To be eligible for the Reset card, individuals must (i) have at least $500
      per month in earnings as reported by a payroll provider, gig platform, or
      other source of income compatible with Reset and (ii) have their earnings
      direct deposited to an eligible deposit account linked to a Reset card.
      Reset Visa cardholders who also e-file their tax returns through april and
      are expected to receive a tax refund of at least $1,000 direct deposited
      to an eligible deposit account may be eligible for advances based on their
      anticipated refund. Other eligibility requirements apply. The Reset card
      is not currently available to individuals in Kansas, Maryland, Missouri,
      Montana, Nevada, South Carolina, or Wisconsin. Tax refund advances are not
      currently available in California.
    </p>
    <p>
      The Tax Filing offer is only available to those with a Reset card who
      qualify for advances based on an anticipated tax refund. Other eligibility
      requirements apply. Those who receive a tax filing fee credit will receive
      the credit through their Reset card. Offer expires on April 15, 2025, or
      the date that available funds dedicated to the offer have been exhausted,
      whichever comes first. Offer, eligibility, and availability subject to
      change without further notice. See terms for details.
    </p>
  </>
);

export default TaxEligibilityFooter;
