import { ApiNotification, ApiNotificationType } from "utils/api/notifications";
import { ApiUserProfile } from "utils/api/user";
import { NotificationUi } from "../types";
import { getIntegrationErrorNotificationUi } from "./integrationError";
import { getOnboardingStateNotificationUi } from "./onboardingState";
import { OPTED_TO_SKIP_PAYROLL_LINKING_NOTIFICATION } from "./optedToSkipPayroll";

export const getNotificationUi: ({
  notification,
  userProfile,
}: {
  notification: ApiNotification;
  userProfile: ApiUserProfile;
}) => NotificationUi | undefined = ({ notification, userProfile }) => {
  switch (notification.type) {
    case ApiNotificationType.integrationError:
      return getIntegrationErrorNotificationUi(notification.params.source);
    case ApiNotificationType.onboardingState:
      return getOnboardingStateNotificationUi({ notification, userProfile });
    case ApiNotificationType.optedToSkipPayrollLinking:
      return OPTED_TO_SKIP_PAYROLL_LINKING_NOTIFICATION;
    default:
      return undefined;
  }
};
