import React from "react";
import { ButtonLevel, ButtonLink } from "components/ui/Button";

const Cta = ({ canApplyForEwa, hasAdvance }) => {
  if (canApplyForEwa) {
    return (
      <ButtonLink level={ButtonLevel.cta} href="/apply?showIntro=true">
        {hasAdvance ? "Add Income to Reset Card" : "Apply Now"}
      </ButtonLink>
    );
  }

  return (
    <ButtonLink level={ButtonLevel.cta} href="/dashboard">
      Go to Dashboard
    </ButtonLink>
  );
};

export default Cta;
