import ContentLayout from "components/layout/ContentLayout";
import React from "react";
import styled from "styled-components";
import { mediaMax, mediaMin, shadows, zIndex } from "utils/theme";

const Wrapper = styled.div`
  width: 100%;

  ${mediaMax("lg")} {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: ${zIndex.nav};

    display: flex;
    align-items: center;
    gap: 24px;
    padding: 16px 0;

    background: white;
    box-shadow: ${shadows.xlarge};
  }
`;

const Inner = styled(ContentLayout)`
  display: flex;
  align-items: center;
  gap: 16px;

  & > * {
    width: 100%;
  }

  ${mediaMin("lg")} {
    flex-direction: column;
    padding: 0;
    max-width: none;
    width: 100%;
    gap: 24px;
  }
`;

interface Props {
  children: React.ReactNode;
}

const MobileFixedFooter: React.FC<Props> = ({ children }) => (
  <Wrapper>
    <Inner>{children}</Inner>
  </Wrapper>
);

export default MobileFixedFooter;
