import React from "react";
import {
  TimelineEventStatus,
  TimelineEventStepStatus,
} from "components/ui/Timeline";
import { ApiDashboardOverview, ApiDashboardPayday } from "utils/api/dashboard";
import { formatCurrency } from "utils/string";
import { ApiUserProfile, RepaymentMethod } from "utils/api/user";

const getRemainderTitle = (userProfile: ApiUserProfile) => {
  switch (userProfile?.repaymentMethod) {
    case RepaymentMethod.directDepositFlow:
      return "Remainder deposited";
    case RepaymentMethod.achDebitFlow:
    default:
      return "Net paycheck";
  }
};

const getPayPeriodEndDescription = (status: TimelineEventStatus) => {
  if (status === TimelineEventStatus.past) return false;

  return "Your earnings will continue to count toward your spending limit until payday.";
};

const getPaydayDescriptionForRepayment = ({
  bankName,
  repaymentMethod,
}: {
  bankName?: string;
  repaymentMethod?: RepaymentMethod;
}) => {
  switch (repaymentMethod) {
    case RepaymentMethod.directDepositFlow:
      return (
        <>
          We've forwarded the remainder of your paycheck to your linked{" "}
          {bankName ? <strong>{bankName}</strong> : "checking"} account.
          Processing time depends on {bankName || "your bank"}, but in most
          cases your deposit will arrive in a few hours.
        </>
      );
    case RepaymentMethod.achDebitFlow:
    default:
      return "Your outstanding transactions were automatically repaid.";
  }
};

const getPaydayDescription = ({
  paycheckReceived,
  status,
  bankName,
  repaymentMethod,
}: {
  paycheckReceived: boolean;
  status: TimelineEventStatus;
  bankName?: string;
  repaymentMethod: RepaymentMethod;
}) => {
  if (status === TimelineEventStatus.past) return false;

  if (paycheckReceived) {
    return (
      <>
        <strong>You got paid!</strong>{" "}
        {getPaydayDescriptionForRepayment({ bankName, repaymentMethod })}
      </>
    );
  }

  if ([TimelineEventStatus.next, TimelineEventStatus.today].includes(status)) {
    return "The exact amount we receive from your payroll provider may still differ from the amount shown below.";
  }

  return <>If payday were today&hellip;</>;
};

const getPaycheckStepStatus = ({
  paycheckReceived,
  status,
}: {
  paycheckReceived: boolean;
  status: TimelineEventStatus;
}) => {
  let stepStatus =
    status === TimelineEventStatus.today
      ? TimelineEventStepStatus.pending
      : TimelineEventStepStatus.incomplete;
  if (paycheckReceived) {
    stepStatus = TimelineEventStepStatus.complete;
  }
  return stepStatus;
};

const getPaycheckStepTitle = ({
  paycheckReceived,
  status,
}: {
  paycheckReceived: boolean;
  status: TimelineEventStatus;
}) => {
  let title = "Paycheck received";
  if (status === TimelineEventStatus.today && !paycheckReceived) {
    title = "Awaiting paycheck";
  }
  return title;
};

const getRepaymentStepStatus = (paycheckReceived: boolean) =>
  paycheckReceived
    ? TimelineEventStepStatus.complete
    : TimelineEventStepStatus.incomplete;

export const getEvents = ({
  dashboard,
  payday,
  userProfile,
}: {
  dashboard: ApiDashboardOverview;
  payday: ApiDashboardPayday;
  userProfile: ApiUserProfile;
}) => {
  if (!payday || !dashboard) return [];

  const {
    currentPayPeriodEnd,
    currentPayday,
    paycheckAmount,
    repaymentAmount,
    remainderAmount,
    paycheckReceived,
  } = payday;

  const paydayEvent = {
    date: currentPayday,
    data: (status: TimelineEventStatus) => ({
      title: status === TimelineEventStatus.today ? "Payday 🎉" : "Payday",
      description: getPaydayDescription({
        paycheckReceived,
        status,
        bankName: dashboard?.linkedBank?.name,
        repaymentMethod: userProfile?.repaymentMethod,
      }),
      steps: [
        {
          status: getPaycheckStepStatus({ paycheckReceived, status }),
          title: getPaycheckStepTitle({ paycheckReceived, status }),
          subtitle: dashboard?.payroll?.name || "Payroll provider",
          detail: formatCurrency(paycheckAmount, true),
        },
        {
          status: getRepaymentStepStatus(paycheckReceived),
          title: "Advances repaid",
          subtitle: "Reset card",
          detail: formatCurrency(repaymentAmount, true),
        },
        {
          status: getRepaymentStepStatus(paycheckReceived),
          title: getRemainderTitle(userProfile),
          subtitle: dashboard?.linkedBank?.name || "Checking account",
          detail: formatCurrency(remainderAmount, true),
        },
      ],
    }),
  };
  if (currentPayday === currentPayPeriodEnd) {
    return [paydayEvent];
  }
  const payPeriodEndEvent = {
    date: currentPayPeriodEnd,
    data: (status: TimelineEventStatus) => ({
      title: "End of Pay Period",
      description: getPayPeriodEndDescription(status),
    }),
  };
  return [payPeriodEndEvent, paydayEvent];
};
