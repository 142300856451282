import React, { useEffect, useRef, useState } from "react";
import Button, { ButtonLevel } from "components/ui/Button";
import InfoBox from "components/ui/InfoBox";
import PinwheelModal, { ModalApi } from "components/shared/PinwheelModal";
import FormContentLayout from "components/layout/FormContentLayout";
import {
  getBankAccountVerificationStatus,
  getDashboardOverview,
} from "store/api/apiSlice";
import VerificationMessage from "./VerificationMessage";
import Faqs from "./Faqs";
import CancelApplicationButton from "../CancelApplicationButton";

interface Props {
  onSuccess: () => void;
}

const DepositSwitching: React.FC<Props> = ({ onSuccess }) => {
  const modalRef = useRef<ModalApi>(null);
  const [isLoadingPinwheel, setIsLoadingPinwheel] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const { data: verificationStatus, isLoading: isLoadingVerificationStatus } =
    getBankAccountVerificationStatus({
      pollingInterval: !isVerified ? 5000 : undefined,
    });
  const { data: dashboard } = getDashboardOverview();

  const openModal = () => {
    modalRef.current?.open();
  };

  useEffect(() => {
    setIsVerified(verificationStatus?.currentStatus);
  }, [isLoadingVerificationStatus, verificationStatus]);

  if (isLoadingVerificationStatus || !isVerified)
    return <VerificationMessage />;

  const bankName = dashboard?.linkedBank?.name;

  return (
    <>
      <FormContentLayout
        heading="Set up autopay."
        subheading={
          <>
            We verified your identity! To complete the setup process, we just
            need to switch your direct deposit to flow through Reset.
          </>
        }
        actions={
          <Button
            isLoading={isLoadingPinwheel}
            onClick={openModal}
            level={ButtonLevel.cta}
          >
            Continue
          </Button>
        }
        footer={<CancelApplicationButton />}
      >
        <Faqs bankName={bankName} />

        <InfoBox title="Payday">
          Your paycheck will still arrive in your{" "}
          {bankName ? <strong>{bankName}</strong> : "linked bank"} account on
          payday!
        </InfoBox>
      </FormContentLayout>

      <PinwheelModal
        ref={modalRef}
        onSubmit={() => {
          setIsLoadingPinwheel(true);
        }}
        onSuccess={onSuccess}
        isDds
        closeOnSuccess
      />
    </>
  );
};

export default DepositSwitching;
