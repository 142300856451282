import React, { useRef, useState } from "react";
import Button, { ButtonLevel } from "components/ui/Button";
import PlaidModal, {
  ModalApi as PlaidModalApi,
} from "components/shared/PlaidModal";
import IconList from "components/ui/IconList";
import FormContentLayout from "components/layout/FormContentLayout";
import TextWithProps from "components/shared/TextWithProps";
import { PartnerSlug, ProductName } from "types/general";
import { CONTENT, DEFAULT_CONTENT } from "./constants";

interface Props {
  productName?: ProductName;
  partnerSlug?: PartnerSlug;
  setIsPolling: (isPolling: boolean) => void;
}

const BankLinking: React.FC<Props> = ({
  productName,
  partnerSlug,
  setIsPolling,
}) => {
  const modalRef = useRef<PlaidModalApi>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const { items, heading, subheading } =
    CONTENT[productName] || DEFAULT_CONTENT;

  const openModal = () => {
    if (modalRef && modalRef.current) {
      modalRef.current.open();
      setIsPolling(false);
    }
  };

  const onSubmitPlaid = () => {
    setIsSuccess(true);
    setIsPolling(true);
  };

  return (
    <>
      <FormContentLayout
        heading={
          <TextWithProps props={{ partnerSlug }}>{heading}</TextWithProps>
        }
        subheading={
          <TextWithProps props={{ partnerSlug }}>{subheading}</TextWithProps>
        }
        actions={
          <Button
            isLoading={isSuccess}
            onClick={openModal}
            level={ButtonLevel.cta}
            data-test="btn-link-bank"
          >
            Connect My Checking Account
          </Button>
        }
      >
        <IconList items={items} isLarge />
      </FormContentLayout>

      <PlaidModal ref={modalRef} onSubmit={onSubmitPlaid} />
    </>
  );
};

export default BankLinking;
