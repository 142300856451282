import styled from "styled-components";
import { colors, mediaMin } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const RefundAmounts = styled.div`
  display: grid;
  gap: 24px;

  ${mediaMin("md")} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const RefundTotal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 4px;
`;

export const RefundSubtotals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Item = styled.div`
  display: flex;
  gap: 12px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 56px;
  height: 56px;

  border-radius: 9999px;
  background: ${colors.azure}2A;
`;

export const ItemContent = styled.div`
  display: grid;
  padding-top: 4px;
`;
