import React from "react";
import Loading from "components/ui/Loading";
import { getDashboardOverview, getUserProfile } from "store/api/apiSlice";
import Card from "../Card";
import Header from "../Header";
import DashboardTabs from "../DashboardTabs";
import * as Styled from "./styled";

const Dashboard: React.FC = () => {
  const { data: userProfile, isLoading: isProfileLoading } = getUserProfile();
  const { data: dashboard, isLoading: isDashboardLoading } =
    getDashboardOverview();

  if (isProfileLoading || isDashboardLoading) return <Loading />;

  return (
    <Styled.Wrapper>
      {userProfile && <Header userProfile={userProfile} />}

      <Styled.DashboardMain>
        {userProfile?.currentCardAccount && (
          <Styled.CardWrapper>
            <Card
              amount={dashboard?.dsl?.currentAmount}
              nextPayday={dashboard?.nextPayday}
            />
          </Styled.CardWrapper>
        )}

        <DashboardTabs userProfile={userProfile} />
      </Styled.DashboardMain>
    </Styled.Wrapper>
  );
};

export default Dashboard;
