import dayjs from "dayjs";
import { API_PATH } from "utils/api/common";
import { COMPENSATION_AMOUNT, MERCHANTS } from "./constants";

export const apiPath = (path) => `${API_PATH}/${path}`;

export const formatDate = (date) => date.format("YYYY-MM-DD");

export const getCurrentDate = () => formatDate(dayjs());

export const getFalse = () => false;
export const getTrue = () => true;

export const getRandomAmount = (minimum: number = 50, maximum: number = 1000) =>
  parseFloat((minimum + Math.random() * (maximum - minimum)).toFixed(2));

export const getRandomKey = (obj: { [key: string]: any }) => {
  const keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]]; // eslint-disable-line no-bitwise
};

export const getRandomInArray = (arr: any[]) =>
  arr[(arr.length * Math.random()) << 0]; // eslint-disable-line no-bitwise

export const getRandomMerchant = () => getRandomInArray(MERCHANTS);

export const getRecurringExpenses = (db, user) =>
  db.recurringExpense.findMany({
    where: { userId: { equals: user.id } },
  });

export const getShifts = (db, user) =>
  db.shift.findMany({
    where: { userId: { equals: user.id } },
    orderBy: { endDate: "desc" },
  });

export const getPaystubs = (db, user) =>
  db.paystub.findMany({
    where: { userId: { equals: user.id } },
    orderBy: { payDate: "desc" },
  });

export const getCardTransactions = (db, user) =>
  db.cardTransaction.findMany({
    where: { userId: { equals: user.id } },
    orderBy: { createdAt: "desc" },
  });

const getDsl = ({
  accruedNetEarnings,
  availableBankBalance,
  upcomingExpensesAmount,
  outstandingResetTransactions,
}) =>
  Math.max(
    Math.min(
      accruedNetEarnings * 0.5,
      accruedNetEarnings +
        Math.min(0, availableBankBalance - upcomingExpensesAmount)
    ) - outstandingResetTransactions,
    0
  );

export const getDashboardOverview = (db, user) => {
  const shifts = getShifts(db, user);
  const nextPayday = dayjs().add(11, "days");
  const recurringExpenses = getRecurringExpenses(db, user).filter((expense) => {
    const date = dayjs(expense.nextOccurrenceDate);
    return date.isSame(nextPayday, "day") || date.isBefore(nextPayday);
  });
  const cardTransactions = getCardTransactions(db, user);
  const accruedNetEarnings = shifts.reduce(
    (total, { earnings }) =>
      total +
      earnings.reduce(
        (sum, earning) => sum + (earning.hours * COMPENSATION_AMOUNT) / 100,
        0
      ),
    0
  );
  const outstandingResetTransactions = cardTransactions.reduce(
    (acc, transaction) => acc + transaction.amount,
    0
  );
  const upcomingExpensesAmount = recurringExpenses.reduce(
    (acc, expense) => acc + expense.averageAmount,
    0
  );
  const availableBankBalance = user.availableBankBalance || 0;
  const dsl = getDsl({
    accruedNetEarnings,
    availableBankBalance,
    upcomingExpensesAmount,
    outstandingResetTransactions,
  });

  return {
    dsl: {
      currentAmount: dsl,
      maxAmount: dsl,
    },
    nextPayday: formatDate(nextPayday),
    payroll: {
      name: user.accounts.payroll,
      amount: accruedNetEarnings,
    },
    linkedBank: {
      name: user.accounts.primaryBank,
      amount: availableBankBalance,
    },
    resetCard: {
      name: "Reset Card",
      amount: outstandingResetTransactions,
    },
    upcomingExpensesAmount,
  };
};
