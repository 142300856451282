import { ApiUserRequest } from "utils/api/user";
import { getSessionPartner } from "utils/partners";
import { SignupFormData } from "./types";

export const getSignupBody = (values: SignupFormData) => {
  const partner = getSessionPartner();
  const productName = sessionStorage.getItem("productName");

  return {
    ...values,
    productName,
    referralSlug: partner?.slug,
  } as ApiUserRequest;
};
