import React, { useEffect, useRef, useState } from "react";
import { getNotifications, getPinwheelStatus } from "store/api/apiSlice";
import { session } from "utils/storage";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import { currentTime } from "utils/date";
import { hasDslUpdatingNotification } from "utils/notifications";
import { DashboardAccountAction } from "./types";

const hasRelinkedPayroll = () => {
  const relinkedAt = session.getItem("relinkedPayrollAt") as string | undefined;
  return !!relinkedAt;
};

const setRelinkedPayrollStorage = (timestamp: string) => {
  session.setItem("relinkedPayrollAt", timestamp);
};

const usePayrollAction: () => DashboardAccountAction = () => {
  const pinwheelModalRef = useRef<PinwheelModalApi>(null);
  const [fetchPinwheelStatus, setFetchPinwheelStatus] = useState(false);
  const [canRelinkPayroll, setCanRelinkPayroll] =
    useState(!hasRelinkedPayroll());
  const [title, setTitle] = useState("Update");
  const { data: pinwheelStatus, isLoading: isLoadingPinwheelStatus } =
    getPinwheelStatus({ skip: !fetchPinwheelStatus });
  const { data: notifications, refetch: updateNotifications } =
    getNotifications();

  const onRelinkComplete = () => {
    updateNotifications();
    setCanRelinkPayroll(false);
    const timestamp = currentTime();
    setTitle(`Updated at ${timestamp}`);
    setRelinkedPayrollStorage(timestamp);
  };

  const onRelinkExit = () => {
    setFetchPinwheelStatus(false);
  };

  const pinwheelRelinkHandler = async () => {
    if (!pinwheelStatus.monitoringOk && pinwheelModalRef?.current) {
      pinwheelModalRef.current.open();
    } else {
      onRelinkComplete();
    }
  };

  const checkPinwheelStatus = () => {
    setFetchPinwheelStatus(true);
  };

  useEffect(() => {
    if (hasRelinkedPayroll()) {
      const timestamp = session.getItem("relinkedPayrollAt");
      setTitle(`Updated at ${timestamp}`);
    }
  }, []);

  useEffect(() => {
    if (pinwheelStatus) {
      pinwheelRelinkHandler();
    }
  }, [pinwheelStatus]);

  const isUpdatingDsl = hasDslUpdatingNotification(notifications);
  const disabled =
    isLoadingPinwheelStatus || isUpdatingDsl || !canRelinkPayroll;

  return {
    onClick: checkPinwheelStatus,
    title,
    icon: canRelinkPayroll ? "refresh" : "checkmark",
    disabled,
    toRender: (
      <PinwheelModal
        ref={pinwheelModalRef}
        onSuccess={onRelinkComplete}
        onExit={onRelinkExit}
      />
    ),
  };
};

export default usePayrollAction;
