import { ApiDashboardOverview } from "./api/dashboard";
import {
  ApiNotification,
  ApiNotificationSource,
  ApiNotificationType,
} from "./api/notifications";

const isIntegrationError = (notification) =>
  [ApiNotificationSource.pinwheel, ApiNotificationSource.plaid].includes(
    notification.params?.source
  ) && notification.type === ApiNotificationType.integrationError;

export const getIntegrationErrors = (notifications: ApiNotification[]) =>
  notifications.filter(isIntegrationError);

export const hasNotification = (
  type: ApiNotificationType,
  notifications: ApiNotification[] = []
) => notifications.some((notification) => notification.type === type);

export const hasDslUpdatingNotification = (
  notifications: ApiNotification[] = []
) => hasNotification(ApiNotificationType.dslUpdating, notifications);

export const hasDslUpdatedNotification = (
  notifications: ApiNotification[] = []
) => hasNotification(ApiNotificationType.dslUpdated, notifications);

export const getIntegrationErrorMessage = (
  integrationError: ApiNotification,
  dashboard: ApiDashboardOverview
) => {
  switch (integrationError.params.source) {
    case ApiNotificationSource.plaid:
      return `Please reconnect your ${dashboard?.linkedBank?.name || "checking"} account.`;
    case ApiNotificationSource.pinwheel:
      return `Please reconnect your ${dashboard?.payroll?.name || "payroll"} account.`;
    default:
      return null;
  }
};
