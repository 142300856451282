import React from "react";
import { HelpMessage } from "components/form";
import { getUserProfile } from "store/api/apiSlice";
import * as Styled from "./styled";

interface Props {
  withAuth?: boolean;
}

const EmailConfirmationAlert: React.FC<Props> = ({ withAuth }) => {
  const { data: userProfile } = getUserProfile({ skip: !withAuth });

  if (!withAuth || userProfile?.confirmed) return null;

  return (
    <Styled.AlertWrapper>
      <HelpMessage>
        We sent an email to <strong>{userProfile?.email}</strong>. Please check
        your inbox and click the link to confirm your email address.
      </HelpMessage>
    </Styled.AlertWrapper>
  );
};

export default EmailConfirmationAlert;
