import React, { useState } from "react";
import CardFront from "components/shared/ResetCard/CardFront";
import Button, { ButtonLevel } from "components/ui/Button";
import FormContentLayout from "components/layout/FormContentLayout";
import Loading from "components/ui/Loading";
import { ApiDashboardDsl } from "utils/api/dashboard";
import { getUserProfile } from "store/api/apiSlice";
import * as Styled from "./styled";
import ProbationarySteps from "./ProbationarySteps";
import BasicExplanation from "./BasicExplanation";

interface Props {
  setShowDslExplanation: (showDslExplanation: boolean) => void;
  dsl: ApiDashboardDsl;
}

const DslExplanation: React.FC<Props> = ({ setShowDslExplanation, dsl }) => {
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    getUserProfile();
  const [currentStep, setCurrentStep] = useState(0);

  const updateStep = (index: number) => {
    setCurrentStep(index);
  };

  const { currentAmount = 0, maxAmount = 0 } = dsl || {};
  const amount = currentStep === 0 ? currentAmount : maxAmount;

  if (isLoadingUserProfile) return <Loading />;

  return (
    <FormContentLayout
      heading="How it works"
      actions={
        <Button
          onClick={() => setShowDslExplanation(false)}
          level={ButtonLevel.cta}
        >
          Continue
        </Button>
      }
    >
      <Styled.CardWrapper>
        <CardFront amount={amount || 0} isActive />
      </Styled.CardWrapper>

      {userProfile?.enableProbationaryLimit ? (
        <ProbationarySteps updateStep={updateStep} currentStep={currentStep} />
      ) : (
        <BasicExplanation />
      )}
    </FormContentLayout>
  );
};

export default DslExplanation;
