import React, { useEffect, useState } from "react";
import { Heading } from "components/ui/Text";
import { getAdminUserPinwheelStatus } from "store/admin/api/apiSlice";
import Button from "components/ui/Button";
import {
  ApiAdminUserPaystub,
  SafeToSpendComponents,
} from "utils/api/admin/user";
import { formatDateString } from "utils/date";
import { formatCurrency } from "utils/string";
import * as Styled from "./styled";
import Pinwheel from "./Pinwheel";

interface Props {
  safeToSpendComponents: SafeToSpendComponents;
  lastPaystub?: ApiAdminUserPaystub;
  userId: string;
}

const Payroll: React.FC<Props> = ({
  safeToSpendComponents,
  lastPaystub,
  userId,
}) => {
  const [showPinwheelStatus, setShowPinwheelStatus] = useState(false);
  const { data: pinwheelStatus, isLoading: isLoadingPinwheelStatus } =
    getAdminUserPinwheelStatus(userId, { skip: !showPinwheelStatus });

  useEffect(() => {
    setShowPinwheelStatus(false);
  }, [userId]);

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2">Payroll</Heading.H4>

      {(safeToSpendComponents || lastPaystub) && (
        <Styled.InfoSection>
          {safeToSpendComponents?.nextPayday && (
            <Styled.InfoItem
              label="Next Payday"
              value={formatDateString(safeToSpendComponents.nextPayday)}
            />
          )}
          {lastPaystub && (
            <>
              <Styled.InfoItem
                label="Employer Name"
                value={lastPaystub.employerName}
              />
              <Styled.InfoItem
                label="Last Payday"
                value={formatDateString(lastPaystub.payDate)}
              />
              <Styled.InfoItem
                label="Last Pay Period Start"
                value={formatDateString(lastPaystub.payPeriodStartDate)}
              />
              <Styled.InfoItem
                label="Last Pay Period End"
                value={formatDateString(lastPaystub.payPeriodEndDate)}
              />
              <Styled.InfoItem
                label="Last Pay Net Ratio"
                value={
                  (lastPaystub.netPayAmountInt || 0) /
                  (lastPaystub.grossPayAmountInt || 1)
                }
              />
              <Styled.InfoItem
                label="Last Pay Gross Amount"
                value={
                  formatCurrency(
                    (lastPaystub.grossPayAmountInt || 0) / 100.0,
                    true
                  ) || "n/a"
                }
              />
              <Styled.InfoItem
                label="Last Pay Net Amount"
                value={
                  formatCurrency(
                    (lastPaystub.netPayAmountInt || 0) / 100.0,
                    true
                  ) || "n/a"
                }
              />
              <Styled.InfoItem
                label="Last Pay Check Amount"
                value={
                  formatCurrency(
                    (lastPaystub.checkAmountInt || 0) / 100.0,
                    true
                  ) || "n/a"
                }
              />
            </>
          )}

          {pinwheelStatus ? (
            <Pinwheel {...pinwheelStatus} />
          ) : (
            <Styled.ButtonWrapper>
              <Button
                isLoading={isLoadingPinwheelStatus}
                onClick={() => setShowPinwheelStatus(true)}
              >
                Show Pinwheel Status
              </Button>
            </Styled.ButtonWrapper>
          )}
        </Styled.InfoSection>
      )}
    </Styled.SectionTile>
  );
};

export default Payroll;
