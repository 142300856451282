import React, { useEffect, useState } from "react";
import FormContainer from "components/form/FormContainer";
import { usePostUserMutation } from "store/api/apiSlice";
import { getApiErrors } from "utils/api/common";
import { PartnerSlug, ProductName } from "types/general";
import SignupForm from "./Form";
import Intro from "./Intro";
import { getSignupBody } from "./utils";
import { SignupFormData } from "./types";

const DEFAULT_VALUES = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
  },
};

interface Props {
  productName: ProductName;
  partnerSlug?: PartnerSlug;
}

const Signup: React.FC<Props> = ({ productName, partnerSlug }) => {
  const [postSignup, postSignupContext] = usePostUserMutation({
    fixedCacheKey: "signup",
  });
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showIntro]);

  const onSubmit = async (values: SignupFormData) => {
    if (values.user?.email?.length > 255) return;

    const body = getSignupBody(values);

    await postSignup(body);
  };

  if (showIntro)
    return (
      <Intro
        hideIntro={() => setShowIntro(false)}
        productName={productName}
        partnerSlug={partnerSlug}
      />
    );

  return (
    <FormContainer
      onSubmit={onSubmit}
      defaultValues={DEFAULT_VALUES}
      errors={getApiErrors(postSignupContext.error)}
    >
      <SignupForm />
    </FormContainer>
  );
};

export default Signup;
