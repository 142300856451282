import React from "react";
import { ProductName } from "types/general";

export const getHeading = (productName: ProductName) => {
  switch (productName) {
    case ProductName.taxRefundAdvance:
      return "Tell us about yourself.";
    case ProductName.earnedWageAccess:
    default:
      return <>Next we'll verify your&nbsp;identity.</>;
  }
};
