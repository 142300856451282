import React from "react";
import Loading from "components/ui/Loading";
import ProductOnboardingFlow from "components/shared/ProductOnboarding/Flow";
import { getCardApplicationStatus, getUserProfile } from "store/api/apiSlice";
import { OnboardingTaskName } from "types/onboarding";
import Step from "./Step";
import * as Styled from "./styled";

const CardApplication: React.FC = () => {
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    getUserProfile();
  const { data: cardApplicationStatus, isLoading: isLoadingTasks } =
    getCardApplicationStatus();

  if (isLoadingUserProfile || isLoadingTasks) {
    return (
      <Styled.LoadingWrapper>
        <Loading />
      </Styled.LoadingWrapper>
    );
  }

  const tasks = cardApplicationStatus?.tasks || [];

  return (
    <ProductOnboardingFlow
      withAuth
      partnerSlug={userProfile?.partnerSlug}
      tasks={tasks}
      showChatWidget
    >
      {({ showInfo }) => (
        <Styled.FormStep>
          <Step
            taskName={
              cardApplicationStatus?.currentTaskName as OnboardingTaskName
            }
            showInfo={showInfo}
          />
        </Styled.FormStep>
      )}
    </ProductOnboardingFlow>
  );
};

export default CardApplication;
