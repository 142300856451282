import React from "react";
import styled from "styled-components";
import LoadingDots from "components/ui/LoadingDots";
import Tile from "components/ui/Tile";

const StyledTile = styled(Tile)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const LoadingTile = () => (
  <StyledTile>
    <LoadingDots />
  </StyledTile>
);

export default LoadingTile;
