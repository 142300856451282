import React, { useEffect, useRef, useState } from "react";
import Button, { ButtonLevel } from "components/ui/Button";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import { ModalApi } from "components/modal/Modal";
import ConfirmActionModal from "components/modal/ConfirmActionModal";
import FormContentLayout from "components/layout/FormContentLayout";
import IconList from "components/ui/IconList";
import { LinkButton } from "components/ui/Link";
import { CardApplicationStatus } from "utils/api/cardApplication";
import { usePostCardApplicationSkipPayrollMutation } from "store/api/apiSlice";
import { LIST_ITEMS } from "./constants";
import * as Styled from "../styled";

interface Props {
  onboardingStatus: CardApplicationStatus;
  onSuccess: () => void;
  setIsPolling: (isPolling: boolean) => void;
  setIsManualEntry: (isManualEntry: boolean) => void;
}

const PayrollLinking: React.FC<Props> = ({
  onboardingStatus,
  onSuccess,
  setIsPolling,
  setIsManualEntry,
}) => {
  const pinwheelModal = useRef<PinwheelModalApi>(null);
  const skipModal = useRef<ModalApi>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [postSkipPayroll, postSkipPayrollContext] =
    usePostCardApplicationSkipPayrollMutation({
      fixedCacheKey: "skipPayroll",
    });

  const skip = () => {
    postSkipPayroll();
  };

  useEffect(() => {
    if (postSkipPayrollContext.isSuccess) {
      onSuccess();
    }
  }, [postSkipPayrollContext.isSuccess]);

  const openModal = () => {
    if (pinwheelModal && pinwheelModal.current) {
      pinwheelModal.current.open();
      setIsPolling(false);
    }
  };

  if (!onboardingStatus) return null;

  return (
    <>
      <FormContentLayout
        heading="Next let's connect your payroll account."
        footer={
          !onboardingStatus.skippable && (
            <LinkButton onClick={() => setIsManualEntry(true)} size="lg">
              I'm having trouble connecting
            </LinkButton>
          )
        }
        actions={
          <>
            {onboardingStatus.skippable && (
              <Button
                level={ButtonLevel.ghost}
                onClick={() => skipModal.current.show()}
              >
                Skip for Now
              </Button>
            )}
            <Button
              isLoading={isLoading}
              onClick={openModal}
              data-test="btn-link-payroll"
              level={ButtonLevel.cta}
            >
              Connect
            </Button>
          </>
        }
      >
        <IconList items={LIST_ITEMS} isLarge />
      </FormContentLayout>

      <Styled.ModalWrapper>
        <PinwheelModal
          ref={pinwheelModal}
          onSubmit={() => {
            setIsLoading(true);
          }}
          onExit={() => {
            setIsPolling(true);
          }}
          onSuccess={onSuccess}
        />
      </Styled.ModalWrapper>

      {onboardingStatus.skippable && (
        <ConfirmActionModal
          ref={skipModal}
          title={<>Are you sure you want to skip this&nbsp;step?</>}
          body="Linking your payroll account will allow you to access a larger
      portion of your income."
          action={skip}
          actionLabel="Yes, Skip for Now"
          cancelLabel="Go Back"
        />
      )}
    </>
  );
};

export default PayrollLinking;
