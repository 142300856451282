import React from "react";
import { Body, Heading } from "components/ui/Text";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import * as Styled from "./styled";

export const { Wrapper } = Styled;
export const { Header } = Styled;

const Content: React.FC<{ useTile?: boolean; children: React.ReactNode }> = ({
  useTile,
  children,
}) => {
  if (useTile) {
    return <Styled.ContentTile>{children}</Styled.ContentTile>;
  }

  return <Styled.Content>{children}</Styled.Content>;
};

interface Props {
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  footer?: React.ReactNode;
  useTile?: boolean;
}

const FormContentLayout: React.FC<Props> = ({
  heading,
  subheading,
  children,
  actions,
  footer,
  useTile,
}) => (
  <Wrapper>
    {(heading || subheading) && (
      <Header>
        {heading && <Heading.H3 tag="h1">{heading}</Heading.H3>}
        {subheading && <Body>{subheading}</Body>}
      </Header>
    )}

    <Content useTile={useTile}>{children}</Content>

    {(actions || footer) && (
      <Styled.Footer>
        {actions && <MobileFixedFooter>{actions}</MobileFixedFooter>}

        {footer}
      </Styled.Footer>
    )}
  </Wrapper>
);

export default FormContentLayout;
