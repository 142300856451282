import React from "react";

interface Props {
  children: React.ReactNode | ((props: any) => React.ReactNode);
  props?: any;
}

const TextWithProps: React.FC<Props> = ({ children, props }) => {
  if (typeof children === "function") {
    return children(props);
  }

  return children;
};

export default TextWithProps;
