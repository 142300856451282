import React from "react";
import { motion } from "framer-motion";
import ResetCard from "components/shared/ResetCard";
import { getDashboardOverview } from "store/api/apiSlice";
import { MarqetaApi } from "utils/hooks/useMarqeta";

const AnimatedResetCard = ({
  marqeta,
  showDslZeroModal,
}: {
  marqeta: MarqetaApi;
  showDslZeroModal: () => void;
}) => {
  const { data: dashboard } = getDashboardOverview();

  const currentAmount = dashboard?.dsl?.currentAmount || 0;

  return (
    <motion.div
      initial={{ y: 100, rotateY: -180, scale: 0.7 }}
      animate={{ y: 0, rotateY: 0, scale: 1 }}
      style={{ transformPerspective: 1000 }}
      transition={{ duration: 0.5 }}
    >
      <ResetCard
        amount={currentAmount}
        isLoadingDetails={marqeta.isLoading}
        isShownDetails={marqeta.isShown}
        showDetails={marqeta.show}
        hideDetails={marqeta.hide}
        cardElementIds={marqeta.ids}
        showDslZeroModal={showDslZeroModal}
      />
    </motion.div>
  );
};

export default AnimatedResetCard;
