import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { usePostUserMutation } from "store/api/apiSlice";
import { toHash } from "utils/string";
import { EventType, trackEvent } from "utils/analytics";
import FormContentLayout from "components/layout/FormContentLayout";
import { SubmitButton } from "components/form";
import Fields from "./Fields";

interface Props {}

const Form: React.FC<Props> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_postSignup, postSignupContext] = usePostUserMutation({
    fixedCacheKey: "signup",
  });

  const form = useFormContext();

  const onSuccess = async (resetPasswordToken) => {
    const userId = await toHash(form.getValues("user.email"));
    trackEvent(EventType.userSignupSubmitted, {
      user_id: userId,
      eventCallback: () => {
        window.location.href = `/users/password/edit?reset_password_token=${resetPasswordToken}&signup=true`;
      },
    });
  };

  useEffect(() => {
    if (postSignupContext.data?.resetPasswordToken) {
      onSuccess(postSignupContext.data.resetPasswordToken);
    }
  }, [postSignupContext.data]);

  return (
    <FormContentLayout
      heading="Let's get started."
      actions={
        <SubmitButton fullWidth data-test="btn-submit">
          Continue
        </SubmitButton>
      }
    >
      <Fields />
    </FormContentLayout>
  );
};

export default Form;
