import React from "react";
import FormContentLayout from "components/layout/FormContentLayout";
import DisappearingTile from "components/ui/DisappearingTile";
import Icon from "components/ui/Icon";
import { Body, BodyLarge, Heading } from "components/ui/Text";
import { HelpMessage } from "components/form";
import UILabel from "components/ui/UILabel";
import Loading from "components/ui/Loading";
import { getCardApplicationStatus } from "store/api/apiSlice";
import useTaxReturnsWithAdvance from "utils/hooks/useTaxReturnsWithAdvance";
import * as Styled from "./styled";
import Cta from "./Cta";
import { formatAmount, getRefundAmountsFromIncomeTaxReturns } from "./utils";
import { REFUND_JURISDICTIONS } from "./constants";

const TaxRefund = () => {
  const {
    taxReturns,
    isLoading: isLoadingTaxReturns,
    hasEligibleReturns,
    refundAmount,
  } = useTaxReturnsWithAdvance();
  const {
    data: ewaApplicationStatus,
    isLoading: isLoadingEwaApplicationStatus,
  } = getCardApplicationStatus();

  if (isLoadingTaxReturns || isLoadingEwaApplicationStatus) return <Loading />;

  const amounts = getRefundAmountsFromIncomeTaxReturns(taxReturns);
  const canApplyForEwa = !ewaApplicationStatus?.cardApplicationComplete;

  return (
    <FormContentLayout
      heading={
        <>
          Your taxes are <em>done.</em>
        </>
      }
      subheading="It feels good to say that, doesn't it?"
      actions={
        <Cta canApplyForEwa={canApplyForEwa} hasAdvance={hasEligibleReturns} />
      }
    >
      <Styled.Wrapper>
        <DisappearingTile size="xl">
          {amounts && (
            <Styled.RefundAmounts>
              <Styled.RefundTotal>
                <UILabel.Micro wideCaps bold color="violet">
                  Estimated Total Refund
                </UILabel.Micro>
                <Heading.H2 color="azure" tag="div">
                  {formatAmount(refundAmount)}
                </Heading.H2>
              </Styled.RefundTotal>
              <Styled.RefundSubtotals>
                {REFUND_JURISDICTIONS.map((item) => (
                  <Styled.Item key={item.valueKey}>
                    <Styled.IconWrapper>
                      <Icon name={item.icon} color="violet" size="md" />
                    </Styled.IconWrapper>
                    <Styled.ItemContent>
                      <UILabel.Micro wideCaps bold color="violet">
                        {item.title}
                      </UILabel.Micro>
                      <BodyLarge tag="div" color="midnight">
                        {formatAmount(amounts[item.valueKey])}
                      </BodyLarge>
                    </Styled.ItemContent>
                  </Styled.Item>
                ))}
              </Styled.RefundSubtotals>
            </Styled.RefundAmounts>
          )}

          {hasEligibleReturns && (
            <HelpMessage>
              <UILabel.Medium color="violet">
                You'll be able to access a portion of your federal refund as
                soon as your tax return is accepted by the IRS.
              </UILabel.Medium>
            </HelpMessage>
          )}
        </DisappearingTile>

        {canApplyForEwa && (
          <Body>
            The Reset card can do even more&mdash;<strong>all for free</strong>.
            Click below to apply for daily access to your income as you earn it.
            It only takes a few minutes.
          </Body>
        )}
      </Styled.Wrapper>
    </FormContentLayout>
  );
};

export default TaxRefund;
