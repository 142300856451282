import React, { useEffect, useRef, useState } from "react";
import { ModalApi } from "components/modal/Modal";
import Button, { ButtonLevel } from "components/ui/Button";
import AprilModal from "components/modal/AprilModal";
import FormContentLayout from "components/layout/FormContentLayout";
import BankAccount from "components/shared/BankAccount";
import Loading from "components/ui/Loading";
import { FormSection, HelpMessage } from "components/form";
import {
  getBankAccountsTaxRefundDepositDestination,
  getBankAccountVerificationTaxRefundDepositDestinationStatus,
  getUserProfile,
} from "store/api/apiSlice";
import VerificationMessage from "./VerificationMessage";
import { getHelpMessage } from "./utils";

const TaxFiling: React.FC = () => {
  const aprilModal = useRef<ModalApi>(null);
  const [hasRefundDepositAccount, setHasRefundDepositAccount] = useState(false);
  const {
    data: depositAccountStatus,
    isLoading: isLoadingDepositAccountStatus,
  } = getBankAccountVerificationTaxRefundDepositDestinationStatus({
    pollingInterval: !hasRefundDepositAccount ? 5000 : undefined,
  });
  const { data: bankAccount, isLoading: isLoadingBankAccount } =
    getBankAccountsTaxRefundDepositDestination();
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    getUserProfile();

  useEffect(() => {
    setHasRefundDepositAccount(depositAccountStatus?.currentStatus);
  }, [depositAccountStatus]);

  if (
    isLoadingDepositAccountStatus ||
    isLoadingBankAccount ||
    isLoadingUserProfile
  )
    return <Loading />;

  if (!hasRefundDepositAccount) return <VerificationMessage />;

  return (
    <>
      <FormContentLayout
        heading="Let's do your taxes."
        actions={
          <Button
            fullWidth
            level={ButtonLevel.cta}
            onClick={() => aprilModal.current.show()}
          >
            Continue
          </Button>
        }
      >
        <FormSection>
          <HelpMessage>{getHelpMessage(userProfile?.partnerSlug)}</HelpMessage>

          <BankAccount bankAccount={bankAccount} />
        </FormSection>
      </FormContentLayout>

      <AprilModal ref={aprilModal} />
    </>
  );
};

export default TaxFiling;
