import React from "react";
import SidebarLayout from "components/layout/SidebarLayout";
import DrawerLayout from "components/layout/DrawerLayout";
import FormProgressSidebar from "components/ui/FormProgressSidebar";
import FormProgressHeader from "components/ui/FormProgressHeader";
import ChatWidget from "components/ui/ChatWidget";
import { ButtonLevel } from "components/ui/Button";
import AppFooter from "components/shared/AppFooter";
import UILabel from "components/ui/UILabel";
import { PartnerSlug, ProductName } from "types/general";
import { FormStepStatus } from "types/task";
import { OnboardingTask } from "types/onboarding";
import { colors } from "utils/theme";
import { logout } from "utils/api/user";
import {
  getUserProfile,
  usePostAdminUserStopImpersonatingMutation,
} from "store/api/apiSlice";
import EmailConfirmationAlert from "./EmailConfirmationAlert";
import {
  getMobileNavigation,
  getProductConfig,
  getShowInfo,
  getSidebarNavigation,
  mapTasksToSteps,
} from "./utils";
import * as Styled from "./styled";
import { InfoType } from "../types";

interface RenderProps {
  showInfo: (info: InfoType) => () => void;
}

interface Props {
  children: React.ReactNode | ((props: RenderProps) => React.ReactNode);
  tasks?: OnboardingTask[];
  productName?: ProductName;
  partnerSlug?: PartnerSlug;
  withAuth?: boolean;
  showChatWidget?: boolean;
}

const Flow: React.FC<Props> = ({
  tasks,
  productName,
  partnerSlug,
  children,
  withAuth,
  showChatWidget,
}) => {
  const { data: userProfile } = getUserProfile({ skip: !withAuth });
  const [postStopImpersonatingUser] =
    usePostAdminUserStopImpersonatingMutation();

  const stopImpersonatingUser = async () => {
    await postStopImpersonatingUser();
    window.location.replace(`/admin/users/${userProfile.id}`);
  };

  const { title, icon, defaultTasks, eligibilityFooter, hideDisclosure } =
    getProductConfig(productName);
  const formSteps = mapTasksToSteps({
    tasks: tasks || defaultTasks,
    productName,
  });
  const currentStepIndex = formSteps.findIndex(
    (step) => step.status === FormStepStatus.ready
  );

  const sidebarNavigation = getSidebarNavigation({
    userProfile,
    stopImpersonatingUser,
    logout,
    withAuth,
  });
  const mobileNavigation = getMobileNavigation({
    userProfile,
    logout,
    withAuth,
  });

  return (
    <SidebarLayout
      sidebar={
        <Styled.SidebarWrapper>
          <FormProgressSidebar
            title={title}
            icon={icon}
            steps={formSteps}
            navigation={sidebarNavigation}
            partnerSlug={partnerSlug}
          />
        </Styled.SidebarWrapper>
      }
    >
      <Styled.MobileHeaderWrapper>
        <FormProgressHeader
          currentStepIndex={currentStepIndex}
          steps={formSteps}
          icon={icon}
          title={title}
          navigation={mobileNavigation}
        />
      </Styled.MobileHeaderWrapper>

      <DrawerLayout>
        {({ openWith, close }) => (
          <Styled.ContentWrapper>
            <Styled.Content>
              <EmailConfirmationAlert withAuth={withAuth} />

              {typeof children === "function"
                ? children({ showInfo: getShowInfo({ openWith, close }) })
                : children}
            </Styled.Content>

            <Styled.Footer>
              {eligibilityFooter && (
                <UILabel.Small color={`${colors.midnight}80`}>
                  {eligibilityFooter}
                </UILabel.Small>
              )}

              <AppFooter withAuth={withAuth} hideDisclosure={hideDisclosure} />
            </Styled.Footer>
          </Styled.ContentWrapper>
        )}
      </DrawerLayout>

      <Styled.ChatWrapper>
        {showChatWidget && (
          <ChatWidget
            isPrivate={withAuth}
            buttonProps={{ level: ButtonLevel.tertiary }}
            label="Need Help?"
            icon="commentQuestion"
            positionMobileY="top"
          />
        )}
      </Styled.ChatWrapper>
    </SidebarLayout>
  );
};

export default Flow;
