import styled from "styled-components";
import { mediaMax, mediaMin } from "utils/theme";

export const FormStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FooterMobile = styled.header`
  ${mediaMin("lg")} {
    display: none;
  }
`;

export const Footer = styled.header`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaMax("lg")} {
    display: none;
  }
`;

export const MobileFooterLink = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalWrapper = styled.div`
  position: absolute;
`;
