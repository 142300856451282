import React from "react";
import styled from "styled-components";
import {
  MaskedField,
  MaskType,
  FormSection,
  AddressField,
  HelpMessage,
  SubmitButton,
  Input,
  Field,
} from "components/form";
import { LinkButton } from "components/ui/Link";
import ToggleBlock from "components/cms/components/block/Toggle";
import FormContentLayout from "components/layout/FormContentLayout";
import { ButtonLevel } from "components/ui/Button";
import { Heading } from "components/ui/Text";
import { BlockType } from "types/cms";
import { ProductName } from "types/general";
import { PARTNERS } from "utils/partners/constants";
import { ApiUserProfile } from "utils/api/user";
import { InfoType } from "../../types";
import { getHeading } from "./utils";

const LargeLabel = styled(Heading.H4)`
  margin-bottom: 12px;
`;

interface Props {
  user: ApiUserProfile | undefined;
  showInfo: (infoType: InfoType) => () => void;
  showName: boolean;
  productName: ProductName;
}

const Form: React.FC<Props> = ({ user, showInfo, showName, productName }) => {
  const partnerFaqs = PARTNERS[user?.partnerSlug]?.faqs?.identityVerification;
  const showPartnerFaqs = productName === ProductName.earnedWageAccess;

  return (
    <FormContentLayout
      heading={getHeading(productName)}
      actions={
        <SubmitButton
          name="confirmed"
          value={false}
          fullWidth
          level={ButtonLevel.cta}
        >
          Continue
        </SubmitButton>
      }
    >
      {showPartnerFaqs && partnerFaqs && (
        <ToggleBlock
          type={BlockType.toggle}
          id="partner-faq"
          data={{
            items: partnerFaqs,
          }}
        />
      )}

      {showName && (
        <FormSection>
          <Field label={<LargeLabel>Your name</LargeLabel>} name="name">
            <Input
              id="name"
              name="name"
              value={`${user?.firstName} ${user?.lastName}`}
              disabled
            />
          </Field>

          <HelpMessage>
            To update your name, please{" "}
            <a href="mailto:hello@getreset.co?subject=Update my name">
              contact us
            </a>
            .
          </HelpMessage>
        </FormSection>
      )}

      <FormSection>
        <MaskedField
          maskType={MaskType.phone}
          placeholder="(123) 456-7890"
          name="phoneNumber"
          label={<LargeLabel>What's your phone number?</LargeLabel>}
          required
        />

        <LinkButton onClick={showInfo(InfoType.phone)} color="azure">
          I don't have a US phone number
        </LinkButton>
      </FormSection>

      <FormSection>
        <MaskedField
          maskType={MaskType.date}
          placeholder="mm/dd/yyyy"
          name="dateOfBirth"
          label={<LargeLabel>What's your date of birth?</LargeLabel>}
          required
        />

        <HelpMessage>
          Reset cardholders must be 18 years of age or older.
        </HelpMessage>
      </FormSection>

      <FormSection>
        <AddressField
          name="address"
          label={<LargeLabel>What's your home address?</LargeLabel>}
          required
        />

        <HelpMessage>
          We need this to mail you your Reset Card. Residential addresses only,
          no PO boxes.
        </HelpMessage>

        <LinkButton onClick={showInfo(InfoType.address)} color="azure">
          I don't have a US home address
        </LinkButton>
      </FormSection>

      <FormSection>
        <MaskedField
          maskType={MaskType.ssn}
          placeholder="012-34-5678"
          name="ssn"
          label={<LargeLabel>What's your social security number?</LargeLabel>}
          required
        />

        <HelpMessage>We need this to verify your identity.</HelpMessage>
      </FormSection>
    </FormContentLayout>
  );
};

export default Form;
