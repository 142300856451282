import dayjs from "dayjs";
import { getIncomeTaxReturns } from "store/api/apiSlice";
import { ApiIncomeTaxReturn } from "utils/api/incomeTaxReturns";

const isTaxSeason = () => {
  const currentYear = dayjs().year();
  const dayAfterTaxDeadline = dayjs(`${currentYear}-04-16`);
  return dayjs().isBefore(dayAfterTaxDeadline);
};

const getEligibleReturnsForAdvanceThisYear = (
  incomeTaxReturns: ApiIncomeTaxReturn[]
) =>
  incomeTaxReturns.filter(
    (taxReturn) => taxReturn.refundAdvanceEligible && isTaxSeason()
  );

const getRefundAmount = (eligibleTaxReturns: ApiIncomeTaxReturn[]) =>
  eligibleTaxReturns.reduce(
    (acc, taxReturn) => acc + taxReturn.refundAmount,
    0
  );

const hasAvailableRefundAdvance = (eligibleTaxReturns: ApiIncomeTaxReturn[]) =>
  eligibleTaxReturns.some(
    (taxReturn) => taxReturn.taxReturnStatus === "accepted"
  );

const DEFAULT_RETURN_DATA = {
  taxReturns: [],
  eligibleTaxReturns: [],
  refundAmount: 0,
  refundAdvanceAmount: 0,
  hasEligibleReturns: false,
  isAvailable: false,
};

export interface TaxReturnsWithAdvance {
  taxReturns: ApiIncomeTaxReturn[];
  eligibleTaxReturns: ApiIncomeTaxReturn[];
  refundAdvanceAmount: number;
  refundAmount: number;
  hasEligibleReturns: boolean;
  isAvailable: boolean;
  isLoading: boolean;
}

const useTaxReturnsWithAdvance: () => TaxReturnsWithAdvance = () => {
  const { data, isLoading } = getIncomeTaxReturns();

  if (!data?.incomeTaxReturns) return { ...DEFAULT_RETURN_DATA, isLoading };

  const taxReturns = data.incomeTaxReturns;
  const refundAmount = getRefundAmount(taxReturns);
  const eligibleTaxReturns = getEligibleReturnsForAdvanceThisYear(
    data.incomeTaxReturns
  );
  const refundAdvanceAmount = getRefundAmount(eligibleTaxReturns);
  const isAvailable = hasAvailableRefundAdvance(eligibleTaxReturns);

  return {
    taxReturns,
    eligibleTaxReturns,
    refundAmount,
    refundAdvanceAmount,
    hasEligibleReturns: eligibleTaxReturns.length > 0,
    isAvailable,
    isLoading: false,
  };
};

export default useTaxReturnsWithAdvance;
