import React from "react";
import { ButtonLevel, ButtonSize } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import relinkPayrollImage from "assets/images/general/relink-payroll.png";
import * as Styled from "../styled";
import { NotificationUi } from "../types";

export const OPTED_TO_SKIP_PAYROLL_LINKING_NOTIFICATION: NotificationUi = {
  title: "You might be eligible for a higher spending limit",
  message:
    "Connecting your payroll account may allow you to access a larger portion of your earnings",
  image: relinkPayrollImage,
  actions: ({ onClick, onClickLearnMore }) => [
    <Styled.ActionButton
      onClick={onClick}
      size={ButtonSize.md}
      level={ButtonLevel.cta}
    >
      Connect
    </Styled.ActionButton>,
    <Styled.ActionLinkButton onClick={onClickLearnMore}>
      Learn More
    </Styled.ActionLinkButton>,
  ],
  modalMessage: (
    <>
      <UILabel.Small bold caps color="azure">
        How Reset works
      </UILabel.Small>
      <p>
        Reset uses Pinwheel to securely connect your payroll account and keep
        your data private. This has <strong>no impact</strong> on your direct
        deposit or the timing of your paycheck.
      </p>
    </>
  ),
  isDismissable: true,
};
