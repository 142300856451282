import { LabelNode, NodeType } from "components/ui/TextWithLinks/types";
import {
  ApiDisclosures,
  ApiDisclosureType,
  getDisclosurePathsByType,
} from "utils/api/disclosures";

export type Field = {
  name: string;
  label: LabelNode[];
  value: ApiDisclosureType[];
};

export const getFields = (disclosuresData: ApiDisclosures): Field[] => {
  if (!disclosuresData) {
    return [];
  }

  const disclosurePathsByType = getDisclosurePathsByType(disclosuresData);

  return [
    {
      name: "glba",
      value: ["privacy_policy"],
      label: [
        {
          type: NodeType.text,
          text: "I have read and understand the ",
        },
        {
          type: NodeType.link,
          text: "Reset Privacy Notice",
          data: {
            href: disclosurePathsByType.privacy_policy,
          },
        },
        {
          type: NodeType.text,
          text: ".",
        },
      ],
    },
    {
      name: "agreements",
      value: [
        "card_agreement",
        "deposit_account_agreement",
        "bank_privacy_policy",
      ],
      label: [
        {
          type: NodeType.text,
          text: "I have read and accept the ",
        },
        {
          type: NodeType.link,
          text: "Card Agreement",
          data: {
            href: disclosurePathsByType.card_agreement,
          },
        },
        {
          type: NodeType.text,
          text: ", ",
        },
        {
          type: NodeType.link,
          text: "Deposit Account Agreement",
          data: {
            href: disclosurePathsByType.deposit_account_agreement,
          },
        },
        {
          type: NodeType.text,
          text: ", and ",
        },
        {
          type: NodeType.link,
          text: "Stearns Bank's Privacy Policy",
          data: {
            href: disclosurePathsByType.bank_privacy_policy,
          },
        },
        {
          type: NodeType.text,
          text: ".",
        },
      ],
    },
    {
      name: "ewa",
      value: ["ewa_terms_of_service"],
      label: [
        {
          type: NodeType.text,
          text: "I have read and accept the ",
        },
        {
          type: NodeType.link,
          text: "EWA Terms of Service",
          data: {
            href: disclosurePathsByType.ewa_terms_of_service,
          },
        },
        {
          type: NodeType.text,
          text: ".",
        },
      ],
    },
    {
      name: "ach",
      value: ["ach_authorization_agreement"],
      label: [
        {
          type: NodeType.text,
          text: "I have read and accept the ",
        },
        {
          type: NodeType.link,
          text: "ACH Authorization Agreement",
          data: {
            href: disclosurePathsByType.ach_authorization_agreement,
          },
        },
        {
          type: NodeType.text,
          text: ".",
        },
      ],
    },
  ];
};
