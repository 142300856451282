import React from "react";
import { FormMessage } from "components/form";
import environment from "utils/environment";
import { Eligibility } from "types/signup";
import PayrollQuestionnaire from "./PayrollQuestionnaire";
import * as Styled from "./styled";

interface Props {
  isSubmitting: boolean;
  hasError: { payroll: boolean };
  updateEligibility: (key: "payroll") => (value: Eligibility) => void;
  updateHasError: (key: "payroll") => (value: boolean) => void;
  eligibility: { payroll: Eligibility };
}

const Fields: React.FC<Props> = ({
  hasError,
  updateEligibility,
  updateHasError,
  eligibility,
}) => (
  <Styled.FormSection>
    {hasError.payroll && <FormMessage>Please fill out all fields</FormMessage>}

    <PayrollQuestionnaire
      setEligibility={updateEligibility("payroll")}
      setHasError={updateHasError("payroll")}
    />

    {environment.signupFormDebugMode &&
      process.env.NODE_ENV === "development" && (
        <div>Payroll: {eligibility.payroll}</div>
      )}
  </Styled.FormSection>
);

export default Fields;
